@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/ProximaNova-Bold.eot");
  src: url("./assets/fonts/ProximaNova-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/ProximaNova-Bold.woff2") format("woff2"),
    url("./assets/fonts/ProximaNova-Bold.woff") format("woff"),
    url("./assets/fonts/ProximaNova-Bold.ttf") format("truetype"),
    url("./assets/fonts/ProximaNova-Bold.svg#ProximaNova-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/ProximaNovaT-Thin.eot");
  src: url("./assets/fonts/ProximaNovaT-Thin.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/ProximaNovaT-Thin.woff2") format("woff2"),
    url("./assets/fonts/ProximaNovaT-Thin.woff") format("woff"),
    url("./assets/fonts/ProximaNovaT-Thin.ttf") format("truetype"),
    url("./assets/fonts/ProximaNovaT-Thin.svg#ProximaNovaT-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./assets/fonts/ProximaNova-Regular.eot");
  src: url("./assets/fonts/ProximaNova-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/ProximaNova-Regular.woff2") format("woff2"),
    url("./assets/fonts/ProximaNova-Regular.woff") format("woff"),
    url("./assets/fonts/ProximaNova-Regular.ttf") format("truetype"),
    url("./assets/fonts/ProximaNova-Regular.svg#ProximaNova-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Proxima Nova";
  font-weight: 400;
  background-color: #00000c;
  color: #ffffff;
  overflow-x: hidden;
}

a {
  color: #7ccce0;
  text-decoration: none;
}

a:hover {
  color: #6132fb;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #01000f;
}

img.brand_logo {
  width: 192px;
}

.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #00000c;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.navbar_right {
  display: flex;
  align-items: center;
}

.navbar_right button+button {
  margin-left: 15px;
}

.primary_btn {
  background: rgb(69, 31, 194);
  background: linear-gradient(268deg,
      rgba(69, 31, 194, 1) 0%,
      rgba(97, 50, 252, 1) 100%);
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  border-radius: 100px;
  border: 1px solid #5e30f7;
  padding: 10px 40px;
  transition: 0.2s all;
}
.primary_btn a {
  color: #fff;
  text-decoration: none;
}
.primary_btn:hover {
  background: linear-gradient(268deg,
      rgba(97, 50, 252, 1) 0%,
      rgba(69, 31, 194, 1) 100%);
}
.primary_btn:hover a {
  color: #fff;
}
.address_btn {
  width: 220px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #fff !important;
  text-decoration: none !important;
}

.walletdrop {
  background: #06061d;
}

.walletdrop li a {
  color: #fff;
  text-decoration: none !important;
}

.walletdrop li a:hover {
  background: #4721c7;
  color: #fff;
}

nav.main_navbar {
  z-index: 12;
}
.main_navbar ul{
  list-style-type: none;
  font-size: 22px;
  width: 100%;
  padding: 0;
}
.main_navbar .navbar-brand{
  outline: 0;
}
.main_navbar ul .mob_show{
  display: none;
}
.main_navbar ul a{ 
  color: #fff !important;
  text-decoration: none !important;
  border-bottom: 2px solid #02022d;
  padding: 10px 0px;
  display: block;
  text-transform: uppercase;
  display: flex;
  list-style: 33px;
}
.main_navbar ul a span{ 
  font-size: 25px;
  color: #6132fb;
  font-weight: 700;
  display: block;
  margin-right: 10px;
}
.main_navbar ul .dropdown-item{
 color: #fff !important;
 border: 0;
 padding: 7px 10px !important;
}
.main_navbar ul .dropdown-toggle{
  padding: 0;
  border: 0 !important;
}
.main_navbar ul .dropdown-toggle::after{
  margin-top: 13px;
}
.main_navbar .offcanvas {
  background:#00000c url("./assets/images/sidebg.png") no-repeat center;
}
.main_navbar .offcanvas .btn-close{
  filter: invert(1);
  opacity: 1;
}
.close_shade{
  width: 280px;
  border-radius: 100px;
  margin: auto;
}
.close_shade button{
  width: 100%;
}
.page_header {
  position: relative;
  padding: 100px 0;
  z-index: 10;
}

.page_header h1 {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
}

.page_header h5 {
  font-size: 24px;
  font-weight: 100;
  margin-bottom: 80px;
  line-height: 40px;
}

.page_header h5 span:first-child {
  font-weight: 700;
}

.page_header h5 span:nth-child(2) {
  font-weight: 700;
  color: #7ccce0;
}

.page_header::before {
  content: "";
  background: url("./assets/images/banner_bg_before.png") no-repeat center;
  width: 100%;
  height: 935px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.page_header::after {
  content: "";
  background: #00000c url("./assets/images/banner_bg_after.png") no-repeat center;
  background-blend-mode: color-dodge;
  width: 100%;
  height: 1100px;
  position: absolute;
  top: -75px;
  left: 0;
  z-index: -2;
}

.banner_btn_grps {
  display: flex;
}

.banner_btn_grps a+a {
  margin-left: 15px;
}

.banner_shapes {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.pacman {
  position: absolute;
  left: 50%;
  transform: rotate(45deg);
}

.head,
.mouth {
  height: 36px;
  width: 72px;
}

.head {
  background-color: #0000ff;
  animation: rotate1 both infinite alternate 500ms linear;
  transform-origin: bottom;
  border-top-left-radius: 400px;
  border-top-right-radius: 400px;
}

.mouth {
  background-color: #0000ff;
  animation: rotate2 both infinite alternate 500ms linear;
  transform-origin: top;
  border-bottom-left-radius: 400px;
  border-bottom-right-radius: 400px;
}

@keyframes rotate1 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-45deg);
  }
}

@keyframes rotate2 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(45deg);
  }
}

.banner_circle {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: absolute;
  bottom: 38%;
  right: 0;
  transform: translate(-50%, -50%);
}

.place {
  border: 1px solid #0000ff;
  z-index: 2;
}

.banner_circle .border-animation,
.place {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 100%;
}

.banner_circle .border-animation {
  z-index: 1;
}

.ba1 {
  animation: rounder1 2s linear 2s infinite alternate;
  border: 1px solid #0000ff;
}

@keyframes rounder1 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.39);
  }
}

.ba2 {
  animation: rounder2 2s linear 2s infinite alternate;
  border: 1px solid #0000ff;
}

@keyframes rounder2 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.69);
  }
}

.ba3 {
  animation: rounder3 2s linear 2s infinite alternate;
  border: 1px solid #0000ff;
}

@keyframes rounder3 {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.99);
  }
}

#ct_particle_animate-a461fd71 {
  position: absolute;
  left: 7%;
  bottom: 18%;
}

.shape-animate1 {
  animation: animationFramesTwo 12s infinite linear alternate;
}

#ct_particle_animate-a461fd72 {
  position: absolute;
  left: 45%;
  bottom: 40%;
}

.shape-animate2 {
  animation: animationFramesOne 15s infinite linear alternate;
}

#ct_particle_animate-a461fd73 {
  left: 50%;
  top: 0;
  position: absolute;
}

.shape-animate3 {
  animation: animationFramesOne 12s infinite linear alternate;
}

@keyframes animationFramesOne {
  0% {
    transform: translate(0, 0)rotate(0)
  }

  20% {
    transform: translate(73px, -1px)rotate(36deg)
  }

  40% {
    transform: translate(111px, 72px)rotate(72deg)
  }

  60% {
    transform: translate(93px, 122px)rotate(108deg)
  }

  80% {
    transform: translate(-70px, 72px)rotate(124deg)
  }

  100% {
    transform: translate(0, 0)rotate(0)
  }
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0, 0)rotate(0)
  }

  20% {
    -webkit-transform: translate(73px, -1px)rotate(36deg)
  }

  40% {
    -webkit-transform: translate(111px, 72px)rotate(72deg)
  }

  60% {
    -webkit-transform: translate(83px, 122px)rotate(108deg)
  }

  80% {
    -webkit-transform: translate(-40px, 72px)rotate(124deg)
  }

  100% {
    -webkit-transform: translate(0, 0)rotate(0)
  }
}

@keyframes animationFramesTwo {
  0% {
    transform: translate(0, 0)rotate(0)scale(1)
  }

  20% {
    transform: translate(73px, -1px)rotate(36deg)scale(.9)
  }

  40% {
    transform: translate(141px, 72px)rotate(72deg)scale(1)
  }

  60% {
    transform: translate(83px, 122px)rotate(108deg)scale(1.2)
  }

  80% {
    transform: translate(-40px, 72px)rotate(104deg)scale(1.1)
  }

  100% {
    transform: translate(0, 0)rotate(0)scale(1)
  }
}

@-webkit-keyframes animationFramesTwo {
  0% {
    -webkit-transform: translate(0, 0)rotate(0)scale(1)
  }

  20% {
    -webkit-transform: translate(73px, -1px)rotate(36deg)scale(.9)
  }

  40% {
    -webkit-transform: translate(141px, 72px)rotate(72deg)scale(1)
  }

  60% {
    -webkit-transform: translate(83px, 122px)rotate(108deg)scale(1.2)
  }

  80% {
    -webkit-transform: translate(-40px, 72px)rotate(104deg)scale(1.1)
  }

  100% {
    -webkit-transform: translate(0, 0)rotate(0)scale(1)
  }
}

.main_wrapper {
  z-index: 10;
  position: relative;
}

.main_title {
  color: #6132fb;
  font-size: 48px;
  font-weight: 700;
}

.sub_title {
  font-size: 24px;
  font-weight: 400;
  color: #7ccce1;
  margin: 12px 0 20px;
}

.inner_para {
  font-size: 18px;
  font-weight: 400;
}

section {
  padding: 50px 0;
}

.wallet_section {
  position: relative;
  overflow: hidden;
}

.wallet_section::after {
  content: "";
  background: #00000c url("./assets/images/banner_bg_after.png") no-repeat center;
  background-blend-mode: color-dodge;
  width: 100%;
  height: 1100px;
  position: absolute;
  top: -75px;
  z-index: -2;
}

.wallet_section::before {
  content: "";
  background: url("./assets/images/shadow_shape_01.png") no-repeat center;
  width: 100%;
  height: 423px;
  position: absolute;
  top: 0;
  right: -200px;
  z-index: -1;
}

ul.custom_list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
}

.custom_list li {
  position: relative;
  list-style: none;
  font-size: 18px;
  padding-bottom: 15px;
  padding-left: 10px;
  width: 50%;
}

.custom_list li::before {
  content: "";
  background: url("../src/assets/images/list_icon.png") no-repeat;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 4px;
  left: -30px;
}

.cta_div {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding: 15px 15px 0 50px;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: 1px solid transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(97, 50, 252, 1) 0%, rgba(69, 31, 195, 1) 16%, rgba(2, 1, 16, 1) 35%, rgba(70, 32, 196, 1) 53%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #01000f inset;
  z-index: 1;
}

.cta_div::before {
  content: "";
  position: absolute;
  background: #1f1f68;
  width: 100px;
  height: 100px;
  left: 30px;
  filter: blur(50px);
  z-index: -1;
}

.cta_div::after {
  content: "";
  position: absolute;
  background: #1f1f68;
  width: 100px;
  height: 100px;
  left: 250px;
  bottom: 30px;
  filter: blur(50px);
  z-index: -1;
}

.cta_left_div {
  width: 50%;
}

.cta_right_div {
  width: 50%;
}

.cta_right_div img {
  margin-top: -120px;
  margin-bottom: -12px;
}

.cta_right_div {
  position: relative;
}

.cta_right_div::before {
  content: "";
  width: 200px;
  height: 200px;
  position: absolute;
  top: 30px;
  background: #220f65;
  left: 150px;
  filter: blur(100px);
  z-index: -1;
}

.team_section {
  position: relative;
  z-index: 1;
  padding-top: 100px;
}

.team_section::before {
  content: "";
  background: url('./assets/images/team_banner.png') no-repeat center;
  width: 100%;
  height: 857px;
  position: absolute;
  top: -80px;
  z-index: -1;
}

.team_div {
  text-align: center;
}

.team_div img {
  border-radius: 100%;
  height: 200px;
  margin-bottom: 15px;
}

.team_div h2 {
  font-size: 24px;
  font-weight: 700;
  color: #6132fb;
}

.team_div h3 {
  font-size: 18px;
  font-weight: 400;
}

.faq_section {
  z-index: 10;
  position: relative;
}

.faq_panel {
  margin-top: 30px;
}

.faq_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid #070745;
  transition: 0.2s all;
  cursor: default;
}

.faq_row h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
  width: 35%;
  margin-right: 30px;
}

.faq_row h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  width: 50%;
}

.faq_row img {
  margin-right: 50px;
}

.faq_row:hover {
  transform: scale(1.02);
}

.faq_row:hover h3 {
  color: #7ccce0;
}

.subscribe_div {
  position: relative;
  border-radius: 50px;
  padding: 50px 50px 70px;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: 1px solid transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(90deg, rgba(97, 50, 252, 1) 0%, rgba(69, 31, 195, 1) 16%, rgba(2, 1, 16, 1) 35%, rgba(70, 32, 196, 1) 53%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #01000f inset;
  z-index: 10;
}

.subscribe_div::before {
  content: "";
  position: absolute;
  background: #1f1f68;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  left: 60px;
  bottom: 70px;
  filter: blur(50px);
  z-index: -1;
}

.subscribe_div::after {
  content: "";
  position: absolute;
  background: #220f66;
  border-radius: 100%;
  width: 220px;
  height: 220px;
  right: 50px;
  bottom: 60px;
  filter: blur(50px);
  z-index: -1;
}

.primary_inp {
  border: 1px solid #1b104a !important;
  background: #01010f !important;
  border-radius: 100px;
  color: #ffffff !important;
  font-size: 18px;
  box-shadow: none !important;
  padding: 15px;
}

.primary_inp::placeholder {
  color: #9595a5 !important;
}

.subscribe_div .primary_inp {
  max-width: 60%;
  margin: 0 auto;
  text-align: center;
  z-index: 10;
  position: relative;
}

.subscribe_div .inner_para {
  max-width: 60%;
  margin: 15px auto 30px;
}

.subscribe_div img {
  position: absolute;
  right: 30px;
  bottom: 0;
  z-index: 1;
}

footer.footer {
  background: #000011;
  padding: 250px 0 30px; 
}

footer.footer p a {
  color: #fff;
}

.footer_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscribe_div button.primary_btn {
  position: absolute;
  bottom: -26px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 200px;
}

ul.footer_menu_links,
ul.footer_social_links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

ul.footer_menu_links {
  margin-bottom: 15px;
}

ul.footer_menu_links li {
  position: relative;
}

ul.footer_menu_links li+li {
  margin-left: 30px;
}

ul.footer_menu_links li::after {
  content: "";
  background: #ffffff;
  width: 1px;
  height: 12px;
  position: absolute;
  top: 6px;
  right: -15px;
}

ul.footer_menu_links li:last-child:after {
  display: none;
}

ul.footer_menu_links li a {
  font-size: 15px;
  color: #fff;
  text-decoration: none;
  transition: 0.2s all;
}

ul.footer_menu_links li a:hover {
  color: #7ccce0;
}

.footer_panel_left p {
  margin-bottom: 0;
  font-size: 15px;
}

ul.footer_social_links li+li {
  margin-left: 15px;
}

ul.footer_social_links li a {
  background: #2e1090;
  border-radius: 100%;
  font-size: 20px;
  color: #000011;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

ul.footer_social_links li a:hover {
  background: #592dec;
}

button:focus {
  box-shadow: none !important;
}

.refferal_ban {
  text-align: center;
  margin-top: -75px;
  padding-top: 100px;
  overflow: hidden;
  position: relative;
}

/* .refferal_ban::before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: url("./assets/images/refferal_bg.png") no-repeat center;
  animation: blink infinite 1.5s;
  background-size: cover;
  width: 100%;
  height: 100%;
}
@keyframes blink {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
  
} */
.refferal_ban .primary_btn {
  display: inline-block;
  margin-bottom: 30px;
  background: #06061d;
  border: 1px solid #22225a;
  color: #22225a;
  text-decoration: none;
  width: 144px;
  margin: 0 10px 20px;
}

.refferal_ban .active .primary_btn,
.refferal_ban .primary_btn:hover,
.refferal_ban .primary_btn:focus {
  color: #fff;
  border: 1px solid transparent;
}

.refferal_ban .earn_img {
  margin-left: 70px;
  margin-bottom: -30px;
}

.refferal_ban ul {
  justify-content: center;
  list-style-type: none;
  padding-left: 0;
}

.refferal .box {
  background: linear-gradient(#00000c, #00000c) padding-box, linear-gradient(47deg, #6132fc, #451fc3 0%, #020110 40%, #4620c4 61%) border-box;
  border: 1px solid transparent;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  margin-bottom: 30px;
}

.refferal .box p {
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 24px;
}

.refferal .box h5 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 26px;
  margin-top: 20px;
}

.refferal .box h6 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0;
}

.refferal .box span {
  font-size: 14px;
  color: #0068b3;
}

.refferal .aff_box {
  display: block;
  padding: 20px 22px 25px 25px;
}

.refferal .aff_box input {
  background: transparent;
  border-color: #13134b;
  box-shadow: none !important;
  border-radius: 7px 0 0 7px;
  color: #ffffff;
  border-right: 0;
  padding: 8px 12px;
  font-size: 15px;
}

.refferal .aff_box .input-group-text {
  background: transparent;
  border-color: #13134b;
  box-shadow: none !important;
  border-radius: 0px 7px 7px 0px;
  border-left: 0;
}

.refferal .aff_box .input-group-text img {
  cursor: pointer;
}

.refferal .aff_box ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  padding-left: 0;
}

.refferal .aff_box ul a {
  margin: 0 8px;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #747679;
  border-radius: 50%;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.refferal .aff_box ul a:hover {
  background: #0068b3;
}
.refferal .car_d{
  background: linear-gradient(#00000c, #00000c) padding-box, linear-gradient(47deg, #6132fc, #451fc3 0%, #020110 40%, #4620c4 61%) border-box;
  border: 1px solid transparent;
  border-radius: 13px;
  padding: 25px 30px;
  margin-bottom: 30px;
  width: 100%;
}
.refferal .car_d p{ 
  color: #ffffff; 
  font-size: 24px;
}
.refferal .car_d .h_flx{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
}
.refferal .car_d .h_flx p{
  margin-bottom: 0;
}
.refferal .car_d .dropdown-toggle{
  background: transparent;
  border-color: #13134b;
  font-size: 15px;
  padding-right: 70px;
  position: relative;
  width: 250px;
  height: 50px;
}
.refferal .car_d .dropdown-toggle::after{
    position: absolute;
    right: 10px;
    top: 22px;
}
.refferal .car_d .dropdown-menu{
  background: rgb(0, 0, 12);
}
.refferal .car_d .dropdown-item{
  color: #fff;
}
.refferal .car_d .dropdown-item:hover{
  background: transparent;
  text-decoration: none;
}
.refferal .card_box{
  background: url("./assets/images/card.png") no-repeat center;
  padding: 43px 50px;
  background-size: 563px 259px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.refferal .card_box .logo{
  height: 230px;
  min-width: 30px
}

.refferal .card_box .flx{
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.refferal .card_box .flx div{
  display: flex;
  align-items: center;
  cursor: pointer;
}
.refferal .card_box .flx img{
  margin-right: 10px;
}
.refferal .card_box p,.refferal .card_box span{ 
  margin-bottom: 0px;
  color: #6a3c05;
  font-family: 'Rajdhani', sans-serif;
  font-weight: 600;
  font-size: 21px;
}
.refferal .rdt_TableHeadRow {
  border: 0;
  background: #01011a;
  font-size: 18px;
  color: #76c3d7;
  font-weight: 700;
  border-radius: 10px !important;
  margin-top: 18px;
}
.slide_ban{
  margin-bottom: 100px;
}
.slide_ban .box{ 
  background: url("./assets/images/slidebg.png") no-repeat center;

  text-align: left;
  padding: 45px 100px;
}
.slide_ban .box h2{
  font-size: 80px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 69px;
}
.slide_ban .box h5{
  color: #6132fb;
  font-size: 38px;
  font-weight: 700;
  letter-spacing: 10px;
}
.slide_ban .outbox{
  margin-bottom: 15px;
}
.slide_ban .slick-dots li{
  margin: 0 !important;
}
.slide_ban .slick-dots li button:before{
  color: #6132fb !important;
  font-size: 13px !important;
}
.slide_ban .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #08083c;
  font-size: 13px;
}
.box_bdr {
  border: 1px solid #090947;
  border-radius: 13px !important;
  padding: 22px 15px 22px 30px;
}

.box_bdr h5 {
  color: #fff;
  margin-top: 0;
}

.refferal .left_box .box {
  border-radius: 20px;
  padding: 20px 22px 20px 25px;
  align-items: flex-start;
}

.refferal .left_box .box p {
  margin-bottom: -7px;
}

.refferal .rdt_Table {
  padding: 5px;
  background: transparent;
}

.refferal .rdt_TableRow,
.refferal .rdt_Table {
  border-radius: 10px !important;
}

.refferal .rdt_TableCol:nth-last-child(1) .rdt_TableCol_Sortable>div {
  width: 25px;
}

.refferal .rdt_TableCol_Sortable,
.refferal .rdt_TableCol_Sortable>div {
  overflow: visible;
}

.refferal .rdt_TableRow:nth-child(odd) {
  background: #020224;
  border: 0;
}

.refferal .rdt_TableRow:nth-child(even) {
  background: #04042f;
  border: 0;
}

.refferal .rdt_TableRow .rdt_TableCell:nth-child(1),
.refferal .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  padding-left: 25px;
}

/* .refferal .rdt_TableRow .rdt_TableCell:nth-last-child(1),
.refferal .rdt_TableHeadRow .rdt_TableCol:nth-last-child(1) {
   justify-content: flex-end; 
  padding-right: 100px;
} */

.refferal .rdt_TableCell {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}

.refferal .rdt_TableCell:nth-child(2)>div {
  overflow: visible;
}

.refferal .rdt_Pagination {
  background: transparent;
  color: #fff;
}

.refferal .rdt_Pagination button[disabled] {
  fill: rgb(80 81 83);
}

.refferal .rdt_Pagination button:not([disabled]) {
  fill: rgb(230 233 255);
}

.earn .box {
  background: linear-gradient(#00000c, #00000c) padding-box, linear-gradient(47deg, #6132fc, #451fc3 0%, #020110 40%, #4620c4 61%) border-box;
  border: 1px solid transparent;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 25px;
}

.earn .box::before {
  content: "";
  position: absolute;
  background: #3e3ec3;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  left: 30px;
  top: 30px;
  filter: blur(40px);
}

.earn .box::after {
  content: "";
  position: absolute;
  background: #3e3ec3;
  border-radius: 100%;
  width: 190px;
  height: 190px;
  left: 0px;
  right: 0;
  margin: 0 auto;
  bottom: -25%;
  filter: blur(85px);
}

.earn .inbox {
  padding: 20px 10px;
  background-image: url("./assets/images/earnbox_bg.png");
  background-position: 164% 100%;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.earn .inbox::before {
  content: "";
  position: absolute;
  background: url("./assets/images/earnbox_shadebg.png") no-repeat center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.earn ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 25px;
  border: 1px solid #0a0a41;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  margin-top: 25px;
  padding-top: 22px;
  padding-bottom: 22px;
  height: 283px;
}

.earn .flx {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.earn .flx h3 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0;
}

.earn .flx p {
  color: #33b9d6;
  margin-bottom: 0;
}

.earn ul li {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding: 0 15px;
  margin-bottom: 15px;
}

.earn ul li:nth-last-child(1) {
  margin-bottom: 0;
}

.earn ul li p {
  margin-bottom: 0;
}

.earn ul li span {
  color: #76c3d7;
  text-align: left;
  width: 40%;
}

.earn .flx_btn {
  text-align: center;
}

.earn .flx_btn button {
  font-size: 18px;
  border: 2px solid transparent;
  width: 147px;
  padding: 6px !important;
  margin: 5px 10px;
}

.earn .flx_btn button.harvest {
  background: linear-gradient(270deg, #1795b0, #33b9d6) padding-box, linear-gradient(90deg, #1795b0, #33b9d6) border-box;
  border: 1px solid transparent;
}

.earn .flx_btn button.harvest:hover {
  background: linear-gradient(90deg, #1795b0, #33b9d6) padding-box, linear-gradient(90deg, #1795b0, #33b9d6) border-box;

}

.earn .flx_btn button.stakenow {
  border: 1px solid transparent;
  background: linear-gradient(94deg, #6132fc, #451fc2) padding-box, linear-gradient(276deg, #6132FC, #451FC3) border-box;
}

.earn .flx_btn button.stakenow:hover {
  background: linear-gradient(269deg, #6132fc, #451fc2) padding-box, linear-gradient(276deg, #6132FC, #451FC3) border-box;
}

.earn .flx_btn button.unstake {
  border: 1px solid transparent;
  background: linear-gradient(94deg, #ffb200, #ffca00) padding-box, linear-gradient(276deg, #ffb200, #ffca00) border-box;
}

.earn .flx_btn button.unstake:hover {
  background: linear-gradient(270deg, #ffb200, #ffca00) padding-box, linear-gradient(276deg, #ffb200, #ffca00) border-box;

}

.earn .form_flx {
  display: flex;
  align-items: flex-end;
  margin-top: 25px;
  padding: 0 15px;
  padding-top: 22px;
  border-top: 1px solid #0a0a41;
  padding-bottom: 20px;
}

.earn .form_flx .flx {
  justify-content: space-between;
  padding: 0;
  padding-bottom: 15px;
  font-size: 18px;
}

.earn .form_flx input {
  background: #00000c !important;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #13134b !important;
  color: #fff !important;
  box-shadow: none !important;
}

.earn .form_flx ::placeholder {
  color: #fff !important;
}

.earn .form_flx button {
  border-radius: 8px;
  margin-left: 15px;
}

.earn .minebox ul {
  margin-top: 0px;
  padding-top: 0px;
  height: unset;
  border-top: 0;
  padding-bottom: 40px;
}

.transaction_his .box_bdr {
  padding: 30px;
}
.transaction_his .rdt_TableRow .rdt_TableCell:nth-child(1), .transaction_his .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  max-width: 78px !important;
  padding-right: 0;
}
/* .transaction_his  .rdt_TableCol:nth-last-child(1) .rdt_TableCol_Sortable>div{
  width: 85px;
} */
/* .transaction_his .rdt_TableCell:nth-child(3),
.transaction_his .rdt_TableCol:nth-child(3){
  max-width: 100px;
} */
/* .transaction_his .rdt_TableCell:nth-child(4),
.transaction_his .rdt_TableCol:nth-child(4){
  padding-right: 40px;
} */
/* .transaction_his .rdt_TableCell:nth-child(6),
.transaction_his .rdt_TableCol:nth-child(6) {
  min-width: 210px;
} */
/* .transaction_his .rdt_TableRow .rdt_TableCell:nth-last-child(1),
.transaction_his .rdt_TableHeadRow .rdt_TableCol:nth-last-child(1) {
  padding-right: 70px;
} */
.transaction_his .select_box{
  background-color: transparent;
  border-color: #13134b !important;
  font-size: 15px;
  padding-right: 70px;
  position: relative;
  width: 200px;
  color: #fff;
  height: 50px;
  border-radius: 15px;
  padding: 8px 10px;
  outline: 0 !important;
  position: relative;
  background-image: linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%), radial-gradient(#fff2f200 70%, transparent 72%) !important;
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em !important;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  appearance: none;
  cursor: pointer;
} 
 
.transaction_his .select_box option{
  background: #00000c;
  border-color: #13134b !important;
  font-size: 15px;
  color: #fff;
  padding: 10px !important;
}
.transaction_his .d-flex.flx{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.transaction_his .green{
  color: #4ee39d;
}
.transaction_his .yellow{
  color: #fed850;
}
.transaction_his .red{
  color: #e34e4e;
}
.history_tabs .nav-tabs .nav-item{ 
  margin-bottom: 10px;
}
.history_tabs .nav-item{
  margin-right: 10px;
  height: 41px;
  white-space: nowrap;
}
.history_tabs .nav-link{
  background: #06061d;
    border: 1px solid #22225a !important;
    color: #4f4fa6;
    border-radius: 30px;
}
.history_tabs .nav-link.active{
  color: #fff;
  border: 1px solid #5b2df2 !important;
  background: #06061d;
}
.history_tabs .box_bdr{ 
 padding: 20px;
}
.deposit_box .input-group-text{
 background: transparent;
 border-left:0 ;
 border-color: #0a0a41;
 color: #fff;
}
.deposit_box .input-group-text.bdr{
  border-right: 0;
}
.deposit_box .input-group-text button{
  background: #13134b;
  border: 0;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 400;
}
.deposit_box .input-group input{
  border-right: 0 !important;
}
.deposit_box .form_flx{
  border: 0;
  margin-top: 0;
}
.deposit_box .labl_flx{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
}
.deposit_box .reff_box{
  background: linear-gradient(#00000c,#00000c) padding-box,linear-gradient(47deg,#6132fc,#451fc3,#020110 40%,#4620c4 61%) border-box;
  border: 1px solid transparent;
  border-radius: 15px;
  padding-top: 0;
  padding: 15px;
}
.deposit_box .pad{
  padding: 9px 50px !important;
}
.deposit_box .select_country{
  width: 100%;
  background-color: #00000c !important;
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #13134b !important;
  color: #fff !important;
  box-shadow: none !important;
  outline: 0 !important;
  background-image: linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%), radial-gradient(#fff2f200 70%, transparent 72%) !important;
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em !important;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  appearance: none;
  cursor: pointer;
}
.kyc .sub_title{
  font-weight: 700;
}
.kyc .labl_flx {
  font-size: 20px;
  font-weight: 400;
}
.kyc .reff_box {
  padding: 25px;
}
.kyc .grey{
  font-size: 18px;
  color: #acacb5;
}
.kyc .flx_btn{
  display: flex;
  margin-bottom: 35px;
}
.kyc .flx_btn button{
  background: #06061d;
  border: 1px solid #22225a !important;
  color: #4f4fa6;
  border-radius: 20px;
  padding: 10px 20px !important;
  width: 200px;
}
.kyc .flx_btn button.active{
  color: #fff;
  border: 1px solid #303071;
}
.kyc .file_box{
  background: #07071c;
  border: 1px solid #303071;
  border-radius: 20px;
  padding: 10px;
  color: #44447b;
  text-align: center;
  position: relative;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer !important;
  margin-bottom: 10px;
}
.kyc .file_box input{
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  width: 100%;
}
.kyc .file_box p{
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 0;
}
.kyc .file_box span{
  display: block;
}
.kyc  .nav-link {
  width: 200px;
}
.kyc  .nav{
  justify-content: center;
}
.dashboard .fle_x {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.dashboard .fle_x div{
  display: flex;
}
.dashboard .fle_x div span{
  color: #00ffff;
  font-weight: 700;
  font-size: 24px;
}
.dashboard .fle_x .h5tag{
  font-size: 24px;
  font-weight: 400;
  margin-top: 0;
}
.dashboard .head_flx{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.dashboard .flx_btn button{
  width: unset;
  padding: 7px 15px !important;
}
.dashboard .box .sub_title{
  color: #7ccce1;
}
.dashboard .c_box {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 10px;
}
.dashboard .c_box p{
  margin-bottom: 0;
}
.dashboard .c_box  span{
  color: #76c3d7;
}
.dashboard .c_box:nth-child(odd) {
  background: #020224;
  border: 0;
}
.dashboard .c_box:nth-child(even) {
  background: #04042f;
  border: 0;
}

/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(#00000c,#00000c) padding-box,linear-gradient(47deg,#6132fc,#451fc3,#020110 40%,#4620c4 61%) border-box;
  border: 1px solid transparent;
}
.primary_modal .modal-header {
  border-bottom: 1px solid #150630;
  padding: 20px 24px 15px;
}
.connect_wallet_button {
  color: #111111;
  border-radius: 13px;
  outline: 0;
  line-height: 46px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s all;
  background: linear-gradient(#00000c,#00000c) padding-box,linear-gradient(47deg,#6132fc,#451fc3,#020110 40%,#4620c4 61%) border-box;
  border: 1px solid transparent;
  flex-direction: column;
  padding: 30px 5px;
}
.connect_wallet_button:hover {
  background: linear-gradient(#00000c,#00000c) padding-box,linear-gradient(270deg,#6132fc,#451fc3,#020110 40%,#4620c4 61%) border-box;
}
.primary_modal .modal-header h5 {
  font-size: 27px;
  font-weight: 500;
  margin: 0;
  color: #fff;;
}
.connect_wallet_button img {
  height: 60px;
}
.connect_wallet_button span {
  color: #fff;
  font-size: 21px;
  font-weight: 400;
}
.btn-close {
  width: 32px;
  height: 32px;
  color: #fff;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  box-shadow: none !important;
  filter: invert(1) brightness(20.5);
}
.primary_modal a {
  text-decoration: none;
}
.primary_modal_chg .modal-content{
  border-radius: 15px;
}
.primary_modal_chg h2{
  font-size: 36px;
  line-height: 40px;
}
.primary_modal_chg p{
  font-size: 18px;
}
.primary_modal_chg h6{
  font-size: 20px;
  font-weight: 700;
}
.primary_modal_chg ul{
  font-size: 18px;
  color: #acacb5;
  list-style-type: none;
  margin-bottom: 0;
}
.primary_modal_chg ul li{
  position: relative;
  margin-bottom: 10px;
}
.primary_modal_chg ul li::before{
  content: "";
  position: absolute;
  left: -30px;
  background: #4ee39d;
  border-radius: 2px;
  transform: rotate(45deg);
  top: 7px;
  width: 12px;
  height: 12px;
}
.primary_modal_chg .reff_box .labl{
  font-size: 20px; 
  text-align: center;
  margin-bottom: 10px; 
  color: #fff;
  font-weight: 500;
} 
.primary_modal_chg .reff_box .form_flx{
  max-width: 75%;
  margin: 0 auto 0;
  border: 0;
  padding-top: 0;
}  
.primary_modal_chg .bottom{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.primary_modal_chg .bottom input{
  border: 1px solid #bb7af9;
  background-color: transparent;
  width: 19px;
  height: 19px;
  margin-right: 12px;
  margin-top: 0;
  box-shadow: none !important;
  cursor: pointer;
}
.primary_modal_chg .bottom .form-check{
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #bb7af9;
}
.primary_modal_chg .bottom button{
  width: 215px;
}
.primary_modal_chg .box{
  background: linear-gradient(#07071c,#07071c) padding-box,linear-gradient(47deg,#8148dc,#8148dc,#020110 40%,#0699cc 61%) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.primary_modal_chg .box h4{
  margin: 0;
  font-size: 28px;
  font-weight: 700;
  margin-top: 12px;
}
.primary_modal_chg .shade{
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  color: #070727;
  width: 100%;
  z-index: -1;
}
.primary_modal_chg .row{
  justify-content: space-between;
}
.primary_modal_chg .col-md-6{
  width: 45%;
}
.primary_modal_chg .btn-close,.primary_modal_chg .btn-close img{
  opacity: 1;
  filter: inherit;
  right: 14px;
    top: 20px;
  position: absolute;
}
.primary_modal_chg .box::before {
  content: "";
  position: absolute;
  background: #046898;
  width: 40px;
  height: 40px;
  bottom: 0;
  right: 0;
  border-top-left-radius: 5px;
}
.primary_modal_chg .selected.box::before {
  background: #046898 url("./assets/images/ticmark.png") no-repeat center;
}
.primary_modal_chg .bgimg{
  background: url("./assets/images/earnbox_bg.png") no-repeat right;
  background-position: 142%;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.primary_modal_chg .bgimg::before{
  content: "";
    position: absolute;
    background: #3e3ec3;
    z-index: -1;
    width: 20%;
    height: 20%;
    border-radius: 50%;
    bottom: 0;
    left: 20%;
    filter: blur(51px);
}
.cms_main {
  margin-top: 80px;
  min-height: 90vh;
}
.custom_dropdown {
  background: #3e3ec3;
}
.custom_dropdown .dropdown-item:hover {
  background: rgba(1, 1, 15, 0.3);
}
.check_box input{ 
  margin-right: 8px;
}
/* .slide_ban .outbox{
  background: linear-gradient(to right, #6132fc, #9932cc00);
  background: url("./assets/images/slidebg.png") no-repeat center padding-box, 
  linear-gradient(to right, #6132fc, #9932cc00) border-box;
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: #41147b;
  background-position-x: -1px;
} */
.activate_nfc_modal .modal-content {
  max-width: 600px;
  margin: 0 auto;
}
.activate_nfc_modal .bgimg::before{
  left: 0;
  right: 0;
  margin: 0 auto;
}
.activate_nfc_modal .form_flx{
  max-width: 100% !important;
}
.activate_nfc_modal .labl {
  text-align: left !important;
}
.activate_nfc_modal input{
  border-radius: 12px !important;
  box-shadow: none !important;
  height: 64px;
}
.activate_nfc_modal .input-group input{
  border-right: 0 !important;
  border-radius: 12px 0 0 12px !important;
}
.activate_nfc_modal .input-group-text {
  background: transparent;
  border-radius: 12px;
  border-color: #13134b;
  border-left: 0 !important;
}
.activate_nfc_modal .input-group-text button{
  border-radius: 35px !important;
  font-size: 16px;
  padding: 10px 20px;
}
/* Media Queries */
@media screen and (max-width: 1399px) {
  .refferal .card_box {
    background-size: cover;
    border-radius: 30px;
  }
  .slide_ban .box{
    /* background: url("./assets/images/slidebg.png") no-repeat center padding-box, 
    linear-gradient(to right, #6132fc, #9932cc00) border-box;
    border-radius: 15px;
    border: 1px solid transparent;
    background-size: cover; */
    background-size: 1102px 316px;
  }
}
@media screen and (max-width: 1199px) {
  .cta_div {
    padding: 30px 15px 30px 50px;
  }

  .cta_right_div img {
    margin-top: -70px;
    margin-bottom: -50px;
  }

  .main_title {
    font-size: 42px;
  }

  .subscribe_div .inner_para {
    max-width: 100%;
  }

  .subscribe_div .primary_inp {
    max-width: 80%;
  }

  .refferal .box p {
    font-size: 22px;
  }

  .refferal .box h5 {
    font-size: 24px;
  }

  .refferal .box h6 {
    font-size: 23px;
  }

  .earn ul li span {
    width: 50%;
  }
  .custom_list li{
    width: 100%;
  }
  .slide_ban .box{
    border-radius: 15px;
    padding: 26px 100px;
    /* background: url("./assets/images/slidebg.png") no-repeat center;
    background-size: cover; */
  }
  .slide_ban .outbox{
    background: linear-gradient(to right, #6132fc, #9932cc00);
    background: url("./assets/images/slidebg.png") no-repeat center padding-box, 
    linear-gradient(to right, #6132fc, #9932cc00) border-box;
    border: 1px solid transparent;
    border-radius: 15px;
  }
}

@media screen and (max-width: 991px) {
  .page_header {
    text-align: center;
  }

  .banner_btn_grps {
    justify-content: center;
  }

  .offcanvas.offcanvas-end {
    background: #00000c;
  }

  .offcanvas-header .btn-close {
    filter: invert(1);
  }

  .team_div {
    margin-bottom: 30px;
  }

  .cta_right_div img {
    margin-top: 45px;
  }
  .slide_ban .box h5 {
    font-size: 32px;
    letter-spacing: 5px;
  }
  .slide_ban .box h2 {
    font-size: 60px;
  }
  .primary_modal_chg .col-md-6{
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .deposit_box .labl_flx{
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;
  }
  .deposit_box .input-group-text,  .deposit_box .input-group-text button{
    font-size: 15px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
  .deposit_box .form_flx{
    padding: 0;
  }
  .primary_modal_chg .reff_box .form_flx{
    max-width: 100%;
  }
  .footer_panel {
    display: block;
    text-align: center;
  }

  ul.footer_menu_links,
  ul.footer_social_links {
    justify-content: center;
  }

  ul.footer_menu_links {
    margin-bottom: 8px;
  }

  .footer_panel_right {
    margin-top: 12px;
  }

  .wallet_section::before {
    right: unset;
    left: 0;
  }

  .wallet_section [class*="col-"] img {
    margin-bottom: 20px;
  }

  .cta_div {
    flex-wrap: wrap;
    padding: 20px;
    text-align: center;
  }

  .cta_left_div,
  .cta_right_div {
    width: 100%;
  }

  .cta_right_div img {
    margin-top: 0;
    margin-bottom: -26px;
  }

  .faq_row {
    flex-wrap: wrap;
  }

  .faq_row h2 {
    width: 100%;
    margin-bottom: 15px;
  }

  .faq_row h3 {
    width: 100%;
    margin-top: 15px;
  }

  .page_header h1 {
    font-size: 38px;
  }

  .main_title {
    font-size: 32px;
  }

  .subscribe_div {
    padding: 50px 20px 70px;
  }

  .subscribe_div img {
    z-index: -1;
  }

  .cta_right_div::before,
  .cta_div::after {
    left: 0;
  }

  .refferal_ban .earn_img {
    margin-left: unset;
    margin-bottom: unset;
  }
  .dashboard .head_flx{
    flex-direction: column;
    align-items: flex-start;
  }
  .earn .flx_btn{
    margin: 10px 0;
    display: flex;
    flex-direction: column;
  }
  .earn .flx_btn button{
    margin-left: 0;
  }
  .dashboard .c_box{
    flex-direction: column;
  }
  .refferal .car_d .h_flx{
    flex-direction: column;
    align-items: flex-start;
  }
  .refferal .car_d .h_flx p{
    margin-bottom: 15px;
  }
  .dashboard .fle_x{
    flex-direction: column;
  }
  .slide_ban .box {
    padding: 40px;
  }
  .slide_ban .box h2 {
    font-size: 50px;
}
.slide_ban .box h5 {
  font-size: 26px;
}
.main_navbar ul .mob_show{
  display: block;
} 
.main_navbar .mob_hide{
  display: none;
}  
}

@media screen and (max-width: 575px) {
  .refferal .card_box {
    padding: 43px 20px;
  }
  .refferal .card_box .active_btn{
    font-size: 18px;
    padding: 9px 15px;
  }
  .refferal .card_box .flx{
    display: block;
  }
  .refferal .card_box .flx div{
    align-items: flex-start;
  }
  .refferal .card_box .flx img{
    margin-left: 0 !important;
  }
  .slide_ban .box{
    padding: 10px 15px;
  }
  .slide_ban .box h2 {
    font-size: 42px;
  }
  .slide_ban .box h5{
    letter-spacing: 2px;
  }
  .earn ul li span {
    width: 43%;
  }

  .earn ul {
    height: 100%;
  }

  .earn .form_flx {
    flex-direction: column;
    align-items: flex-start;
  }

  .earn .form_flx button {
    margin: 15px 0 0;
  }

  .minebox ul li p {
    margin-bottom: 0;
    width: 50%;
  } 
}

.snow {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #d6c638;
  border-radius: 50%;
  filter: blur(1px);
  position: absolute;
  top: 0;
}

.snow:nth-child(1) {
  opacity: 0.1333;
  transform: translate(55.1198vw, -10px) scale(0.9056);
  animation: fall-1 12s -19s linear infinite;
}

@keyframes fall-1 {
  45.446% {
    transform: translate(51.6106vw, 45.446vh) scale(0.9056);
  }

  to {
    transform: translate(53.3652vw, 100vh) scale(0.9056);
  }
}

.snow:nth-child(2) {
  opacity: 0.2112;
  transform: translate(57.1384vw, -10px) scale(0.386);
  animation: fall-2 21s -26s linear infinite;
}

@keyframes fall-2 {
  49.506% {
    transform: translate(50.1637vw, 49.506vh) scale(0.386);
  }

  to {
    transform: translate(53.65105vw, 100vh) scale(0.386);
  }
}

.snow:nth-child(3) {
  opacity: 0.3013;
  transform: translate(62.3163vw, -10px) scale(0.1928);
  animation: fall-3 27s -15s linear infinite;
}

@keyframes fall-3 {
  43.142% {
    transform: translate(71.3711vw, 43.142vh) scale(0.1928);
  }

  to {
    transform: translate(66.8437vw, 100vh) scale(0.1928);
  }
}

.snow:nth-child(4) {
  opacity: 0.1843;
  transform: translate(68.3673vw, -10px) scale(0.5914);
  animation: fall-4 15s -3s linear infinite;
}

@keyframes fall-4 {
  38.536% {
    transform: translate(61.7816vw, 38.536vh) scale(0.5914);
  }

  to {
    transform: translate(65.07445vw, 100vh) scale(0.5914);
  }
}

.snow:nth-child(5) {
  opacity: 0.8095;
  transform: translate(62.0254vw, -10px) scale(0.449);
  animation: fall-5 19s -2s linear infinite;
}

@keyframes fall-5 {
  70.752% {
    transform: translate(65.5418vw, 70.752vh) scale(0.449);
  }

  to {
    transform: translate(63.7836vw, 100vh) scale(0.449);
  }
}

.snow:nth-child(6) {
  opacity: 0.636;
  transform: translate(84.7877vw, -10px) scale(0.9878);
  animation: fall-6 11s -12s linear infinite;
}

@keyframes fall-6 {
  72.122% {
    transform: translate(94.3586vw, 72.122vh) scale(0.9878);
  }

  to {
    transform: translate(89.57315vw, 100vh) scale(0.9878);
  }
}

.snow:nth-child(7) {
  opacity: 0.8614;
  transform: translate(43.7059vw, -10px) scale(0.9804);
  animation: fall-7 23s -13s linear infinite;
}

@keyframes fall-7 {
  42.669% {
    transform: translate(53.4387vw, 42.669vh) scale(0.9804);
  }

  to {
    transform: translate(48.5723vw, 100vh) scale(0.9804);
  }
}

.snow:nth-child(8) {
  opacity: 0.7577;
  transform: translate(24.9702vw, -10px) scale(0.3604);
  animation: fall-8 13s -5s linear infinite;
}

@keyframes fall-8 {
  46.139% {
    transform: translate(31.2622vw, 46.139vh) scale(0.3604);
  }

  to {
    transform: translate(28.1162vw, 100vh) scale(0.3604);
  }
}

.snow:nth-child(9) {
  opacity: 0.0646;
  transform: translate(95.3278vw, -10px) scale(0.2456);
  animation: fall-9 17s -19s linear infinite;
}

@keyframes fall-9 {
  47.671% {
    transform: translate(93.8861vw, 47.671vh) scale(0.2456);
  }

  to {
    transform: translate(94.60695vw, 100vh) scale(0.2456);
  }
}

.snow:nth-child(10) {
  opacity: 0.227;
  transform: translate(19.9139vw, -10px) scale(0.4684);
  animation: fall-10 13s -12s linear infinite;
}

@keyframes fall-10 {
  37.842% {
    transform: translate(19.5102vw, 37.842vh) scale(0.4684);
  }

  to {
    transform: translate(19.71205vw, 100vh) scale(0.4684);
  }
}

.snow:nth-child(11) {
  opacity: 0.3377;
  transform: translate(9.4282vw, -10px) scale(0.8051);
  animation: fall-11 28s -26s linear infinite;
}

@keyframes fall-11 {
  30.419% {
    transform: translate(14.7457vw, 30.419vh) scale(0.8051);
  }

  to {
    transform: translate(12.08695vw, 100vh) scale(0.8051);
  }
}

.snow:nth-child(12) {
  opacity: 0.8774;
  transform: translate(73.0229vw, -10px) scale(0.5853);
  animation: fall-12 29s -22s linear infinite;
}

@keyframes fall-12 {
  49.391% {
    transform: translate(64.7324vw, 49.391vh) scale(0.5853);
  }

  to {
    transform: translate(68.87765vw, 100vh) scale(0.5853);
  }
}

.snow:nth-child(13) {
  opacity: 0.8402;
  transform: translate(88.9024vw, -10px) scale(0.2645);
  animation: fall-13 24s -26s linear infinite;
}

@keyframes fall-13 {
  33.264% {
    transform: translate(90.0748vw, 33.264vh) scale(0.2645);
  }

  to {
    transform: translate(89.4886vw, 100vh) scale(0.2645);
  }
}

.snow:nth-child(14) {
  opacity: 0.41;
  transform: translate(2.0186vw, -10px) scale(0.8784);
  animation: fall-14 23s -30s linear infinite;
}

@keyframes fall-14 {
  53.718% {
    transform: translate(2.3409vw, 53.718vh) scale(0.8784);
  }

  to {
    transform: translate(2.17975vw, 100vh) scale(0.8784);
  }
}

.snow:nth-child(15) {
  opacity: 0.18;
  transform: translate(76.207vw, -10px) scale(0.6177);
  animation: fall-15 15s -2s linear infinite;
}

@keyframes fall-15 {
  68.722% {
    transform: translate(80.701vw, 68.722vh) scale(0.6177);
  }

  to {
    transform: translate(78.454vw, 100vh) scale(0.6177);
  }
}

.snow:nth-child(16) {
  opacity: 0.099;
  transform: translate(24.9908vw, -10px) scale(0.6894);
  animation: fall-16 30s -11s linear infinite;
}

@keyframes fall-16 {
  48.383% {
    transform: translate(28.7215vw, 48.383vh) scale(0.6894);
  }

  to {
    transform: translate(26.85615vw, 100vh) scale(0.6894);
  }
}

.snow:nth-child(17) {
  opacity: 0.344;
  transform: translate(1.319vw, -10px) scale(0.6358);
  animation: fall-17 28s -7s linear infinite;
}

@keyframes fall-17 {
  44.153% {
    transform: translate(2.0072vw, 44.153vh) scale(0.6358);
  }

  to {
    transform: translate(1.6631vw, 100vh) scale(0.6358);
  }
}

.snow:nth-child(18) {
  opacity: 0.0324;
  transform: translate(55.0036vw, -10px) scale(0.8311);
  animation: fall-18 28s -29s linear infinite;
}

@keyframes fall-18 {
  67.129% {
    transform: translate(46.6224vw, 67.129vh) scale(0.8311);
  }

  to {
    transform: translate(50.813vw, 100vh) scale(0.8311);
  }
}

.snow:nth-child(19) {
  opacity: 0.144;
  transform: translate(63.9882vw, -10px) scale(0.2801);
  animation: fall-19 24s -27s linear infinite;
}

@keyframes fall-19 {
  45.638% {
    transform: translate(61.1199vw, 45.638vh) scale(0.2801);
  }

  to {
    transform: translate(62.55405vw, 100vh) scale(0.2801);
  }
}

.snow:nth-child(20) {
  opacity: 0.9012;
  transform: translate(68.1252vw, -10px) scale(0.771);
  animation: fall-20 21s -3s linear infinite;
}

@keyframes fall-20 {
  67.894% {
    transform: translate(73.3921vw, 67.894vh) scale(0.771);
  }

  to {
    transform: translate(70.75865vw, 100vh) scale(0.771);
  }
}

.snow:nth-child(21) {
  opacity: 0.1882;
  transform: translate(53.4195vw, -10px) scale(0.9553);
  animation: fall-21 19s -6s linear infinite;
}

@keyframes fall-21 {
  51.457% {
    transform: translate(50.9833vw, 51.457vh) scale(0.9553);
  }

  to {
    transform: translate(52.2014vw, 100vh) scale(0.9553);
  }
}

.snow:nth-child(22) {
  opacity: 0.3591;
  transform: translate(55.4746vw, -10px) scale(0.5335);
  animation: fall-22 30s -3s linear infinite;
}

@keyframes fall-22 {
  30.565% {
    transform: translate(52.4616vw, 30.565vh) scale(0.5335);
  }

  to {
    transform: translate(53.9681vw, 100vh) scale(0.5335);
  }
}

.snow:nth-child(23) {
  opacity: 0.6853;
  transform: translate(97.4897vw, -10px) scale(0.0456);
  animation: fall-23 28s -7s linear infinite;
}

@keyframes fall-23 {
  60.815% {
    transform: translate(99.6439vw, 60.815vh) scale(0.0456);
  }

  to {
    transform: translate(98.5668vw, 100vh) scale(0.0456);
  }
}

.snow:nth-child(24) {
  opacity: 0.0466;
  transform: translate(27.8522vw, -10px) scale(0.0547);
  animation: fall-24 24s -24s linear infinite;
}

@keyframes fall-24 {
  63.294% {
    transform: translate(35.2808vw, 63.294vh) scale(0.0547);
  }

  to {
    transform: translate(31.5665vw, 100vh) scale(0.0547);
  }
}

.snow:nth-child(25) {
  opacity: 0.3049;
  transform: translate(33.7753vw, -10px) scale(0.21);
  animation: fall-25 24s -19s linear infinite;
}

@keyframes fall-25 {
  68.24% {
    transform: translate(29.3457vw, 68.24vh) scale(0.21);
  }

  to {
    transform: translate(31.5605vw, 100vh) scale(0.21);
  }
}

.snow:nth-child(26) {
  opacity: 0.7007;
  transform: translate(23.1443vw, -10px) scale(0.8024);
  animation: fall-26 20s -17s linear infinite;
}

@keyframes fall-26 {
  40.343% {
    transform: translate(31.8815vw, 40.343vh) scale(0.8024);
  }

  to {
    transform: translate(27.5129vw, 100vh) scale(0.8024);
  }
}

.snow:nth-child(27) {
  opacity: 0.3923;
  transform: translate(64.7643vw, -10px) scale(0.2461);
  animation: fall-27 13s -30s linear infinite;
}

@keyframes fall-27 {
  51.466% {
    transform: translate(65.5873vw, 51.466vh) scale(0.2461);
  }

  to {
    transform: translate(65.1758vw, 100vh) scale(0.2461);
  }
}

.snow:nth-child(28) {
  opacity: 0.8288;
  transform: translate(4.626vw, -10px) scale(0.7713);
  animation: fall-28 13s -15s linear infinite;
}

@keyframes fall-28 {
  75.979% {
    transform: translate(-4.9524vw, 75.979vh) scale(0.7713);
  }

  to {
    transform: translate(-0.1632vw, 100vh) scale(0.7713);
  }
}

.snow:nth-child(29) {
  opacity: 0.7417;
  transform: translate(86.5723vw, -10px) scale(0.3571);
  animation: fall-29 11s -2s linear infinite;
}

@keyframes fall-29 {
  55.15% {
    transform: translate(80.8846vw, 55.15vh) scale(0.3571);
  }

  to {
    transform: translate(83.72845vw, 100vh) scale(0.3571);
  }
}

.snow:nth-child(30) {
  opacity: 0.197;
  transform: translate(63.1864vw, -10px) scale(0.6057);
  animation: fall-30 14s -14s linear infinite;
}

@keyframes fall-30 {
  60.579% {
    transform: translate(61.4384vw, 60.579vh) scale(0.6057);
  }

  to {
    transform: translate(62.3124vw, 100vh) scale(0.6057);
  }
}

.snow:nth-child(31) {
  opacity: 0.3922;
  transform: translate(73.4252vw, -10px) scale(0.8049);
  animation: fall-31 25s -4s linear infinite;
}

@keyframes fall-31 {
  74.431% {
    transform: translate(82.2448vw, 74.431vh) scale(0.8049);
  }

  to {
    transform: translate(77.835vw, 100vh) scale(0.8049);
  }
}

.snow:nth-child(32) {
  opacity: 0.5998;
  transform: translate(34.3648vw, -10px) scale(0.5851);
  animation: fall-32 27s -6s linear infinite;
}

@keyframes fall-32 {
  49.75% {
    transform: translate(25.6153vw, 49.75vh) scale(0.5851);
  }

  to {
    transform: translate(29.99005vw, 100vh) scale(0.5851);
  }
}

.snow:nth-child(33) {
  opacity: 0.8571;
  transform: translate(47.0852vw, -10px) scale(0.1086);
  animation: fall-33 19s -14s linear infinite;
}

@keyframes fall-33 {
  61.772% {
    transform: translate(49.1373vw, 61.772vh) scale(0.1086);
  }

  to {
    transform: translate(48.11125vw, 100vh) scale(0.1086);
  }
}

.snow:nth-child(34) {
  opacity: 0.7992;
  transform: translate(89.3905vw, -10px) scale(0.3312);
  animation: fall-34 19s -4s linear infinite;
}

@keyframes fall-34 {
  79.009% {
    transform: translate(84.9345vw, 79.009vh) scale(0.3312);
  }

  to {
    transform: translate(87.1625vw, 100vh) scale(0.3312);
  }
}

.snow:nth-child(35) {
  opacity: 0.778;
  transform: translate(22.3208vw, -10px) scale(0.088);
  animation: fall-35 26s -29s linear infinite;
}

@keyframes fall-35 {
  54.695% {
    transform: translate(24.9622vw, 54.695vh) scale(0.088);
  }

  to {
    transform: translate(23.6415vw, 100vh) scale(0.088);
  }
}

.snow:nth-child(36) {
  opacity: 0.0304;
  transform: translate(31.7211vw, -10px) scale(0.1144);
  animation: fall-36 13s -3s linear infinite;
}

@keyframes fall-36 {
  35.35% {
    transform: translate(29.0471vw, 35.35vh) scale(0.1144);
  }

  to {
    transform: translate(30.3841vw, 100vh) scale(0.1144);
  }
}

.snow:nth-child(37) {
  opacity: 0.5205;
  transform: translate(70.4776vw, -10px) scale(0.2466);
  animation: fall-37 16s -2s linear infinite;
}

@keyframes fall-37 {
  31.284% {
    transform: translate(74.9239vw, 31.284vh) scale(0.2466);
  }

  to {
    transform: translate(72.70075vw, 100vh) scale(0.2466);
  }
}

.snow:nth-child(38) {
  opacity: 0.1217;
  transform: translate(11.3129vw, -10px) scale(0.8316);
  animation: fall-38 27s -12s linear infinite;
}

@keyframes fall-38 {
  39.861% {
    transform: translate(11.9338vw, 39.861vh) scale(0.8316);
  }

  to {
    transform: translate(11.62335vw, 100vh) scale(0.8316);
  }
}

.snow:nth-child(39) {
  opacity: 0.0903;
  transform: translate(38.0569vw, -10px) scale(0.6211);
  animation: fall-39 15s -12s linear infinite;
}

@keyframes fall-39 {
  42.672% {
    transform: translate(46.1136vw, 42.672vh) scale(0.6211);
  }

  to {
    transform: translate(42.08525vw, 100vh) scale(0.6211);
  }
}

.snow:nth-child(40) {
  opacity: 0.2861;
  transform: translate(32.5967vw, -10px) scale(0.9981);
  animation: fall-40 24s -1s linear infinite;
}

@keyframes fall-40 {
  79.305% {
    transform: translate(27.9967vw, 79.305vh) scale(0.9981);
  }

  to {
    transform: translate(30.2967vw, 100vh) scale(0.9981);
  }
}

.snow:nth-child(41) {
  opacity: 0.549;
  transform: translate(17.1096vw, -10px) scale(0.9074);
  animation: fall-41 21s -17s linear infinite;
}

@keyframes fall-41 {
  44.162% {
    transform: translate(9.3162vw, 44.162vh) scale(0.9074);
  }

  to {
    transform: translate(13.2129vw, 100vh) scale(0.9074);
  }
}

.snow:nth-child(42) {
  opacity: 0.3867;
  transform: translate(35.4801vw, -10px) scale(0.4943);
  animation: fall-42 11s -20s linear infinite;
}

@keyframes fall-42 {
  60.774% {
    transform: translate(39.0535vw, 60.774vh) scale(0.4943);
  }

  to {
    transform: translate(37.2668vw, 100vh) scale(0.4943);
  }
}

.snow:nth-child(43) {
  opacity: 0.3798;
  transform: translate(66.6115vw, -10px) scale(0.9834);
  animation: fall-43 19s -23s linear infinite;
}

@keyframes fall-43 {
  72.932% {
    transform: translate(76.3213vw, 72.932vh) scale(0.9834);
  }

  to {
    transform: translate(71.4664vw, 100vh) scale(0.9834);
  }
}

.snow:nth-child(44) {
  opacity: 0.8711;
  transform: translate(71.6328vw, -10px) scale(0.709);
  animation: fall-44 27s -13s linear infinite;
}

@keyframes fall-44 {
  52.9% {
    transform: translate(81.1325vw, 52.9vh) scale(0.709);
  }

  to {
    transform: translate(76.38265vw, 100vh) scale(0.709);
  }
}

.snow:nth-child(45) {
  opacity: 0.5498;
  transform: translate(80.9002vw, -10px) scale(0.5365);
  animation: fall-45 10s -30s linear infinite;
}

@keyframes fall-45 {
  63.122% {
    transform: translate(86.3939vw, 63.122vh) scale(0.5365);
  }

  to {
    transform: translate(83.64705vw, 100vh) scale(0.5365);
  }
}

.snow:nth-child(46) {
  opacity: 0.0422;
  transform: translate(97.4036vw, -10px) scale(0.9126);
  animation: fall-46 22s -9s linear infinite;
}

@keyframes fall-46 {
  55.564% {
    transform: translate(94.2669vw, 55.564vh) scale(0.9126);
  }

  to {
    transform: translate(95.83525vw, 100vh) scale(0.9126);
  }
}

.snow:nth-child(47) {
  opacity: 0.5552;
  transform: translate(9.076vw, -10px) scale(0.1204);
  animation: fall-47 12s -2s linear infinite;
}

@keyframes fall-47 {
  58.544% {
    transform: translate(2.2054vw, 58.544vh) scale(0.1204);
  }

  to {
    transform: translate(5.6407vw, 100vh) scale(0.1204);
  }
}

.snow:nth-child(48) {
  opacity: 0.6283;
  transform: translate(76.9417vw, -10px) scale(0.2833);
  animation: fall-48 17s -30s linear infinite;
}

@keyframes fall-48 {
  66.577% {
    transform: translate(80.7362vw, 66.577vh) scale(0.2833);
  }

  to {
    transform: translate(78.83895vw, 100vh) scale(0.2833);
  }
}

.snow:nth-child(49) {
  opacity: 0.5696;
  transform: translate(70.9118vw, -10px) scale(0.6707);
  animation: fall-49 14s -3s linear infinite;
}

@keyframes fall-49 {
  70.568% {
    transform: translate(80.4453vw, 70.568vh) scale(0.6707);
  }

  to {
    transform: translate(75.67855vw, 100vh) scale(0.6707);
  }
}

.snow:nth-child(50) {
  opacity: 0.4426;
  transform: translate(11.8992vw, -10px) scale(0.0116);
  animation: fall-50 27s -4s linear infinite;
}

@keyframes fall-50 {
  63.68% {
    transform: translate(16.9335vw, 63.68vh) scale(0.0116);
  }

  to {
    transform: translate(14.41635vw, 100vh) scale(0.0116);
  }
}

.snow:nth-child(51) {
  opacity: 0.1263;
  transform: translate(79.963vw, -10px) scale(0.2934);
  animation: fall-51 18s -26s linear infinite;
}

@keyframes fall-51 {
  44.839% {
    transform: translate(81.6409vw, 44.839vh) scale(0.2934);
  }

  to {
    transform: translate(80.80195vw, 100vh) scale(0.2934);
  }
}

.snow:nth-child(52) {
  opacity: 0.3833;
  transform: translate(63.2282vw, -10px) scale(0.3193);
  animation: fall-52 24s -22s linear infinite;
}

@keyframes fall-52 {
  76.491% {
    transform: translate(73.1428vw, 76.491vh) scale(0.3193);
  }

  to {
    transform: translate(68.1855vw, 100vh) scale(0.3193);
  }
}

.snow:nth-child(53) {
  opacity: 0.2509;
  transform: translate(60.6569vw, -10px) scale(0.0307);
  animation: fall-53 27s -14s linear infinite;
}

@keyframes fall-53 {
  62.234% {
    transform: translate(55.8668vw, 62.234vh) scale(0.0307);
  }

  to {
    transform: translate(58.26185vw, 100vh) scale(0.0307);
  }
}

.snow:nth-child(54) {
  opacity: 0.9799;
  transform: translate(12.0695vw, -10px) scale(0.7736);
  animation: fall-54 14s -27s linear infinite;
}

@keyframes fall-54 {
  59.527% {
    transform: translate(5.4158vw, 59.527vh) scale(0.7736);
  }

  to {
    transform: translate(8.74265vw, 100vh) scale(0.7736);
  }
}

.snow:nth-child(55) {
  opacity: 0.7926;
  transform: translate(59.2282vw, -10px) scale(0.2888);
  animation: fall-55 21s -23s linear infinite;
}

@keyframes fall-55 {
  52.35% {
    transform: translate(60.6873vw, 52.35vh) scale(0.2888);
  }

  to {
    transform: translate(59.95775vw, 100vh) scale(0.2888);
  }
}

.snow:nth-child(56) {
  opacity: 0.5942;
  transform: translate(96.0658vw, -10px) scale(0.9178);
  animation: fall-56 12s -15s linear infinite;
}

@keyframes fall-56 {
  43.815% {
    transform: translate(92.0793vw, 43.815vh) scale(0.9178);
  }

  to {
    transform: translate(94.07255vw, 100vh) scale(0.9178);
  }
}

.snow:nth-child(57) {
  opacity: 0.9615;
  transform: translate(60.0305vw, -10px) scale(0.3223);
  animation: fall-57 27s -22s linear infinite;
}

@keyframes fall-57 {
  64.718% {
    transform: translate(57.5216vw, 64.718vh) scale(0.3223);
  }

  to {
    transform: translate(58.77605vw, 100vh) scale(0.3223);
  }
}

.snow:nth-child(58) {
  opacity: 0.2825;
  transform: translate(63.146vw, -10px) scale(0.6653);
  animation: fall-58 18s -12s linear infinite;
}

@keyframes fall-58 {
  43.502% {
    transform: translate(60.4493vw, 43.502vh) scale(0.6653);
  }

  to {
    transform: translate(61.79765vw, 100vh) scale(0.6653);
  }
}

.snow:nth-child(59) {
  opacity: 0.0921;
  transform: translate(8.8558vw, -10px) scale(0.2612);
  animation: fall-59 12s -9s linear infinite;
}

@keyframes fall-59 {
  73.886% {
    transform: translate(14.979vw, 73.886vh) scale(0.2612);
  }

  to {
    transform: translate(11.9174vw, 100vh) scale(0.2612);
  }
}

.snow:nth-child(60) {
  opacity: 0.98;
  transform: translate(98.1824vw, -10px) scale(0.2748);
  animation: fall-60 17s -21s linear infinite;
}

@keyframes fall-60 {
  55.927% {
    transform: translate(102.6714vw, 55.927vh) scale(0.2748);
  }

  to {
    transform: translate(100.4269vw, 100vh) scale(0.2748);
  }
}

.snow:nth-child(61) {
  opacity: 0.9466;
  transform: translate(76.9119vw, -10px) scale(0.851);
  animation: fall-61 27s -11s linear infinite;
}

@keyframes fall-61 {
  50.471% {
    transform: translate(67.0173vw, 50.471vh) scale(0.851);
  }

  to {
    transform: translate(71.9646vw, 100vh) scale(0.851);
  }
}

.snow:nth-child(62) {
  opacity: 0.2233;
  transform: translate(73.7415vw, -10px) scale(0.5363);
  animation: fall-62 20s -6s linear infinite;
}

@keyframes fall-62 {
  41.058% {
    transform: translate(74.4411vw, 41.058vh) scale(0.5363);
  }

  to {
    transform: translate(74.0913vw, 100vh) scale(0.5363);
  }
}

.snow:nth-child(63) {
  opacity: 0.3184;
  transform: translate(19.5406vw, -10px) scale(0.6307);
  animation: fall-63 23s -8s linear infinite;
}

@keyframes fall-63 {
  64.363% {
    transform: translate(22.6809vw, 64.363vh) scale(0.6307);
  }

  to {
    transform: translate(21.11075vw, 100vh) scale(0.6307);
  }
}

.snow:nth-child(64) {
  opacity: 0.451;
  transform: translate(34.4139vw, -10px) scale(0.6614);
  animation: fall-64 12s -15s linear infinite;
}

@keyframes fall-64 {
  64.965% {
    transform: translate(34.4406vw, 64.965vh) scale(0.6614);
  }

  to {
    transform: translate(34.42725vw, 100vh) scale(0.6614);
  }
}

.snow:nth-child(65) {
  opacity: 0.188;
  transform: translate(58.0005vw, -10px) scale(0.9534);
  animation: fall-65 20s -7s linear infinite;
}

@keyframes fall-65 {
  76.591% {
    transform: translate(59.9728vw, 76.591vh) scale(0.9534);
  }

  to {
    transform: translate(58.98665vw, 100vh) scale(0.9534);
  }
}

.snow:nth-child(66) {
  opacity: 0.9665;
  transform: translate(46.4162vw, -10px) scale(0.1391);
  animation: fall-66 29s -3s linear infinite;
}

@keyframes fall-66 {
  72.85% {
    transform: translate(48.7915vw, 72.85vh) scale(0.1391);
  }

  to {
    transform: translate(47.60385vw, 100vh) scale(0.1391);
  }
}

.snow:nth-child(67) {
  opacity: 0.1982;
  transform: translate(80.0008vw, -10px) scale(0.1512);
  animation: fall-67 29s -18s linear infinite;
}

@keyframes fall-67 {
  58.599% {
    transform: translate(77.0082vw, 58.599vh) scale(0.1512);
  }

  to {
    transform: translate(78.5045vw, 100vh) scale(0.1512);
  }
}

.snow:nth-child(68) {
  opacity: 0.5397;
  transform: translate(99.3784vw, -10px) scale(0.572);
  animation: fall-68 18s -3s linear infinite;
}

@keyframes fall-68 {
  72.702% {
    transform: translate(92.7541vw, 72.702vh) scale(0.572);
  }

  to {
    transform: translate(96.06625vw, 100vh) scale(0.572);
  }
}

.snow:nth-child(69) {
  opacity: 0.7926;
  transform: translate(99.3575vw, -10px) scale(0.6312);
  animation: fall-69 29s -20s linear infinite;
}

@keyframes fall-69 {
  39.603% {
    transform: translate(96.2417vw, 39.603vh) scale(0.6312);
  }

  to {
    transform: translate(97.7996vw, 100vh) scale(0.6312);
  }
}

.snow:nth-child(70) {
  opacity: 0.6442;
  transform: translate(18.0864vw, -10px) scale(0.9995);
  animation: fall-70 26s -15s linear infinite;
}

@keyframes fall-70 {
  45.317% {
    transform: translate(19.5549vw, 45.317vh) scale(0.9995);
  }

  to {
    transform: translate(18.82065vw, 100vh) scale(0.9995);
  }
}

.snow:nth-child(71) {
  opacity: 0.9985;
  transform: translate(18.8869vw, -10px) scale(0.8249);
  animation: fall-71 20s -14s linear infinite;
}

@keyframes fall-71 {
  42.696% {
    transform: translate(22.4255vw, 42.696vh) scale(0.8249);
  }

  to {
    transform: translate(20.6562vw, 100vh) scale(0.8249);
  }
}

.snow:nth-child(72) {
  opacity: 0.0646;
  transform: translate(79.9132vw, -10px) scale(0.5926);
  animation: fall-72 13s -6s linear infinite;
}

@keyframes fall-72 {
  77.582% {
    transform: translate(85.4051vw, 77.582vh) scale(0.5926);
  }

  to {
    transform: translate(82.65915vw, 100vh) scale(0.5926);
  }
}

.snow:nth-child(73) {
  opacity: 0.3491;
  transform: translate(6.5061vw, -10px) scale(0.4255);
  animation: fall-73 17s -30s linear infinite;
}

@keyframes fall-73 {
  39.111% {
    transform: translate(14.5765vw, 39.111vh) scale(0.4255);
  }

  to {
    transform: translate(10.5413vw, 100vh) scale(0.4255);
  }
}

.snow:nth-child(74) {
  opacity: 0.7757;
  transform: translate(96.4019vw, -10px) scale(0.11);
  animation: fall-74 26s -8s linear infinite;
}

@keyframes fall-74 {
  38.639% {
    transform: translate(90.1039vw, 38.639vh) scale(0.11);
  }

  to {
    transform: translate(93.2529vw, 100vh) scale(0.11);
  }
}

.snow:nth-child(75) {
  opacity: 0.9932;
  transform: translate(82.0955vw, -10px) scale(0.7305);
  animation: fall-75 22s -26s linear infinite;
}

@keyframes fall-75 {
  74.304% {
    transform: translate(72.5066vw, 74.304vh) scale(0.7305);
  }

  to {
    transform: translate(77.30105vw, 100vh) scale(0.7305);
  }
}

.snow:nth-child(76) {
  opacity: 0.9319;
  transform: translate(56.7996vw, -10px) scale(0.0677);
  animation: fall-76 23s -20s linear infinite;
}

@keyframes fall-76 {
  47.119% {
    transform: translate(55.2841vw, 47.119vh) scale(0.0677);
  }

  to {
    transform: translate(56.04185vw, 100vh) scale(0.0677);
  }
}

.snow:nth-child(77) {
  opacity: 0.0122;
  transform: translate(24.4986vw, -10px) scale(0.0843);
  animation: fall-77 10s -17s linear infinite;
}

@keyframes fall-77 {
  64.33% {
    transform: translate(23.8395vw, 64.33vh) scale(0.0843);
  }

  to {
    transform: translate(24.16905vw, 100vh) scale(0.0843);
  }
}

.snow:nth-child(78) {
  opacity: 0.5617;
  transform: translate(80.5962vw, -10px) scale(0.221);
  animation: fall-78 23s -30s linear infinite;
}

@keyframes fall-78 {
  32.657% {
    transform: translate(85.1657vw, 32.657vh) scale(0.221);
  }

  to {
    transform: translate(82.88095vw, 100vh) scale(0.221);
  }
}

.snow:nth-child(79) {
  opacity: 0.061;
  transform: translate(7.2711vw, -10px) scale(0.8824);
  animation: fall-79 11s -8s linear infinite;
}

@keyframes fall-79 {
  48.825% {
    transform: translate(-1.7807vw, 48.825vh) scale(0.8824);
  }

  to {
    transform: translate(2.7452vw, 100vh) scale(0.8824);
  }
}

.snow:nth-child(80) {
  opacity: 0.0559;
  transform: translate(89.1696vw, -10px) scale(0.564);
  animation: fall-80 17s -28s linear infinite;
}

@keyframes fall-80 {
  40.772% {
    transform: translate(82.1056vw, 40.772vh) scale(0.564);
  }

  to {
    transform: translate(85.6376vw, 100vh) scale(0.564);
  }
}

.snow:nth-child(81) {
  opacity: 0.8047;
  transform: translate(45.5322vw, -10px) scale(0.0424);
  animation: fall-81 17s -13s linear infinite;
}

@keyframes fall-81 {
  31.719% {
    transform: translate(42.7085vw, 31.719vh) scale(0.0424);
  }

  to {
    transform: translate(44.12035vw, 100vh) scale(0.0424);
  }
}

.snow:nth-child(82) {
  opacity: 0.6733;
  transform: translate(6.9134vw, -10px) scale(0.9544);
  animation: fall-82 15s -15s linear infinite;
}

@keyframes fall-82 {
  64.831% {
    transform: translate(9.43vw, 64.831vh) scale(0.9544);
  }

  to {
    transform: translate(8.1717vw, 100vh) scale(0.9544);
  }
}

.snow:nth-child(83) {
  opacity: 0.6595;
  transform: translate(48.6039vw, -10px) scale(0.5612);
  animation: fall-83 21s -16s linear infinite;
}

@keyframes fall-83 {
  39.243% {
    transform: translate(39.2511vw, 39.243vh) scale(0.5612);
  }

  to {
    transform: translate(43.9275vw, 100vh) scale(0.5612);
  }
}

.snow:nth-child(84) {
  opacity: 0.3293;
  transform: translate(84.9862vw, -10px) scale(0.734);
  animation: fall-84 25s -14s linear infinite;
}

@keyframes fall-84 {
  32.222% {
    transform: translate(88.0568vw, 32.222vh) scale(0.734);
  }

  to {
    transform: translate(86.5215vw, 100vh) scale(0.734);
  }
}

.snow:nth-child(85) {
  opacity: 0.1245;
  transform: translate(98.329vw, -10px) scale(0.9909);
  animation: fall-85 19s -24s linear infinite;
}

@keyframes fall-85 {
  45.984% {
    transform: translate(104.4459vw, 45.984vh) scale(0.9909);
  }

  to {
    transform: translate(101.38745vw, 100vh) scale(0.9909);
  }
}

.snow:nth-child(86) {
  opacity: 0.8642;
  transform: translate(56.9356vw, -10px) scale(0.0901);
  animation: fall-86 17s -12s linear infinite;
}

@keyframes fall-86 {
  66.846% {
    transform: translate(60.5311vw, 66.846vh) scale(0.0901);
  }

  to {
    transform: translate(58.73335vw, 100vh) scale(0.0901);
  }
}

.snow:nth-child(87) {
  opacity: 0.8366;
  transform: translate(26.8631vw, -10px) scale(0.341);
  animation: fall-87 22s -5s linear infinite;
}

@keyframes fall-87 {
  56.217% {
    transform: translate(23.0828vw, 56.217vh) scale(0.341);
  }

  to {
    transform: translate(24.97295vw, 100vh) scale(0.341);
  }
}

.snow:nth-child(88) {
  opacity: 0.6658;
  transform: translate(5.1964vw, -10px) scale(0.2438);
  animation: fall-88 25s -18s linear infinite;
}

@keyframes fall-88 {
  38.269% {
    transform: translate(9.4584vw, 38.269vh) scale(0.2438);
  }

  to {
    transform: translate(7.3274vw, 100vh) scale(0.2438);
  }
}

.snow:nth-child(89) {
  opacity: 0.6328;
  transform: translate(47.3388vw, -10px) scale(0.3188);
  animation: fall-89 10s -6s linear infinite;
}

@keyframes fall-89 {
  68.697% {
    transform: translate(52.7189vw, 68.697vh) scale(0.3188);
  }

  to {
    transform: translate(50.02885vw, 100vh) scale(0.3188);
  }
}

.snow:nth-child(90) {
  opacity: 0.244;
  transform: translate(72.1269vw, -10px) scale(0.5841);
  animation: fall-90 13s -2s linear infinite;
}

@keyframes fall-90 {
  44.585% {
    transform: translate(79.318vw, 44.585vh) scale(0.5841);
  }

  to {
    transform: translate(75.72245vw, 100vh) scale(0.5841);
  }
}

.snow:nth-child(91) {
  opacity: 0.1075;
  transform: translate(40.7235vw, -10px) scale(0.2973);
  animation: fall-91 18s -27s linear infinite;
}

@keyframes fall-91 {
  42.1% {
    transform: translate(34.8868vw, 42.1vh) scale(0.2973);
  }

  to {
    transform: translate(37.80515vw, 100vh) scale(0.2973);
  }
}

.snow:nth-child(92) {
  opacity: 0.3662;
  transform: translate(33.074vw, -10px) scale(0.2512);
  animation: fall-92 30s -19s linear infinite;
}

@keyframes fall-92 {
  74.035% {
    transform: translate(34.4464vw, 74.035vh) scale(0.2512);
  }

  to {
    transform: translate(33.7602vw, 100vh) scale(0.2512);
  }
}

.snow:nth-child(93) {
  opacity: 0.3242;
  transform: translate(12.9463vw, -10px) scale(0.7139);
  animation: fall-93 11s -4s linear infinite;
}

@keyframes fall-93 {
  60.39% {
    transform: translate(18.7368vw, 60.39vh) scale(0.7139);
  }

  to {
    transform: translate(15.84155vw, 100vh) scale(0.7139);
  }
}

.snow:nth-child(94) {
  opacity: 0.1402;
  transform: translate(80.8131vw, -10px) scale(0.4918);
  animation: fall-94 23s -29s linear infinite;
}

@keyframes fall-94 {
  48.658% {
    transform: translate(82.9249vw, 48.658vh) scale(0.4918);
  }

  to {
    transform: translate(81.869vw, 100vh) scale(0.4918);
  }
}

.snow:nth-child(95) {
  opacity: 0.025;
  transform: translate(46.7307vw, -10px) scale(0.8337);
  animation: fall-95 22s -29s linear infinite;
}

@keyframes fall-95 {
  33.671% {
    transform: translate(52.9186vw, 33.671vh) scale(0.8337);
  }

  to {
    transform: translate(49.82465vw, 100vh) scale(0.8337);
  }
}

.snow:nth-child(96) {
  opacity: 0.7478;
  transform: translate(56.2086vw, -10px) scale(0.9327);
  animation: fall-96 24s -22s linear infinite;
}

@keyframes fall-96 {
  31.262% {
    transform: translate(62.7892vw, 31.262vh) scale(0.9327);
  }

  to {
    transform: translate(59.4989vw, 100vh) scale(0.9327);
  }
}

.snow:nth-child(97) {
  opacity: 0.6433;
  transform: translate(22.584vw, -10px) scale(0.1137);
  animation: fall-97 20s -6s linear infinite;
}

@keyframes fall-97 {
  67.059% {
    transform: translate(21.6923vw, 67.059vh) scale(0.1137);
  }

  to {
    transform: translate(22.13815vw, 100vh) scale(0.1137);
  }
}

.snow:nth-child(98) {
  opacity: 0.7922;
  transform: translate(21.1018vw, -10px) scale(0.4265);
  animation: fall-98 14s -17s linear infinite;
}

@keyframes fall-98 {
  38.31% {
    transform: translate(26.4176vw, 38.31vh) scale(0.4265);
  }

  to {
    transform: translate(23.7597vw, 100vh) scale(0.4265);
  }
}

.snow:nth-child(99) {
  opacity: 0.51;
  transform: translate(52.8014vw, -10px) scale(0.2088);
  animation: fall-99 29s -22s linear infinite;
}

@keyframes fall-99 {
  66.621% {
    transform: translate(61.751vw, 66.621vh) scale(0.2088);
  }

  to {
    transform: translate(57.2762vw, 100vh) scale(0.2088);
  }
}

.snow:nth-child(100) {
  opacity: 0.9518;
  transform: translate(31.3748vw, -10px) scale(0.6721);
  animation: fall-100 29s -21s linear infinite;
}

@keyframes fall-100 {
  51.962% {
    transform: translate(35.3283vw, 51.962vh) scale(0.6721);
  }

  to {
    transform: translate(33.35155vw, 100vh) scale(0.6721);
  }
}

.snow:nth-child(101) {
  opacity: 0.7066;
  transform: translate(51.9524vw, -10px) scale(0.7223);
  animation: fall-101 17s -2s linear infinite;
}

@keyframes fall-101 {
  61.362% {
    transform: translate(42.1643vw, 61.362vh) scale(0.7223);
  }

  to {
    transform: translate(47.05835vw, 100vh) scale(0.7223);
  }
}

.snow:nth-child(102) {
  opacity: 0.5249;
  transform: translate(5.004vw, -10px) scale(0.1554);
  animation: fall-102 30s -6s linear infinite;
}

@keyframes fall-102 {
  39.81% {
    transform: translate(4.2757vw, 39.81vh) scale(0.1554);
  }

  to {
    transform: translate(4.63985vw, 100vh) scale(0.1554);
  }
}

.snow:nth-child(103) {
  opacity: 0.0215;
  transform: translate(92.2293vw, -10px) scale(0.1242);
  animation: fall-103 25s -24s linear infinite;
}

@keyframes fall-103 {
  76.354% {
    transform: translate(88.2027vw, 76.354vh) scale(0.1242);
  }

  to {
    transform: translate(90.216vw, 100vh) scale(0.1242);
  }
}

.snow:nth-child(104) {
  opacity: 0.1213;
  transform: translate(16.3726vw, -10px) scale(0.7676);
  animation: fall-104 26s -20s linear infinite;
}

@keyframes fall-104 {
  62.593% {
    transform: translate(7.7633vw, 62.593vh) scale(0.7676);
  }

  to {
    transform: translate(12.06795vw, 100vh) scale(0.7676);
  }
}

.snow:nth-child(105) {
  opacity: 0.7651;
  transform: translate(32.9594vw, -10px) scale(0.933);
  animation: fall-105 10s -11s linear infinite;
}

@keyframes fall-105 {
  63.675% {
    transform: translate(41.3777vw, 63.675vh) scale(0.933);
  }

  to {
    transform: translate(37.16855vw, 100vh) scale(0.933);
  }
}

.snow:nth-child(106) {
  opacity: 0.1655;
  transform: translate(72.2221vw, -10px) scale(0.8246);
  animation: fall-106 15s -13s linear infinite;
}

@keyframes fall-106 {
  40.825% {
    transform: translate(70.0464vw, 40.825vh) scale(0.8246);
  }

  to {
    transform: translate(71.13425vw, 100vh) scale(0.8246);
  }
}

.snow:nth-child(107) {
  opacity: 0.5034;
  transform: translate(9.2806vw, -10px) scale(0.6482);
  animation: fall-107 22s -13s linear infinite;
}

@keyframes fall-107 {
  74.166% {
    transform: translate(10.2364vw, 74.166vh) scale(0.6482);
  }

  to {
    transform: translate(9.7585vw, 100vh) scale(0.6482);
  }
}

.snow:nth-child(108) {
  opacity: 0.573;
  transform: translate(58.6869vw, -10px) scale(0.4854);
  animation: fall-108 12s -11s linear infinite;
}

@keyframes fall-108 {
  37.93% {
    transform: translate(51.1138vw, 37.93vh) scale(0.4854);
  }

  to {
    transform: translate(54.90035vw, 100vh) scale(0.4854);
  }
}

.snow:nth-child(109) {
  opacity: 0.1219;
  transform: translate(49.8561vw, -10px) scale(0.4678);
  animation: fall-109 27s -22s linear infinite;
}

@keyframes fall-109 {
  70.537% {
    transform: translate(47.449vw, 70.537vh) scale(0.4678);
  }

  to {
    transform: translate(48.65255vw, 100vh) scale(0.4678);
  }
}

.snow:nth-child(110) {
  opacity: 0.9987;
  transform: translate(55.6002vw, -10px) scale(0.4402);
  animation: fall-110 19s -26s linear infinite;
}

@keyframes fall-110 {
  67.953% {
    transform: translate(55.3556vw, 67.953vh) scale(0.4402);
  }

  to {
    transform: translate(55.4779vw, 100vh) scale(0.4402);
  }
}

.snow:nth-child(111) {
  opacity: 0.5274;
  transform: translate(48.7855vw, -10px) scale(0.0481);
  animation: fall-111 11s -16s linear infinite;
}

@keyframes fall-111 {
  38.353% {
    transform: translate(54.8565vw, 38.353vh) scale(0.0481);
  }

  to {
    transform: translate(51.821vw, 100vh) scale(0.0481);
  }
}

.snow:nth-child(112) {
  opacity: 0.3185;
  transform: translate(54.8742vw, -10px) scale(0.7906);
  animation: fall-112 14s -10s linear infinite;
}

@keyframes fall-112 {
  63.929% {
    transform: translate(56.3395vw, 63.929vh) scale(0.7906);
  }

  to {
    transform: translate(55.60685vw, 100vh) scale(0.7906);
  }
}

.snow:nth-child(113) {
  opacity: 0.3665;
  transform: translate(61.6445vw, -10px) scale(0.2882);
  animation: fall-113 12s -6s linear infinite;
}

@keyframes fall-113 {
  33.511% {
    transform: translate(58.2088vw, 33.511vh) scale(0.2882);
  }

  to {
    transform: translate(59.92665vw, 100vh) scale(0.2882);
  }
}

.snow:nth-child(114) {
  opacity: 0.3836;
  transform: translate(4.8478vw, -10px) scale(0.4304);
  animation: fall-114 11s -20s linear infinite;
}

@keyframes fall-114 {
  42.947% {
    transform: translate(0.3659vw, 42.947vh) scale(0.4304);
  }

  to {
    transform: translate(2.60685vw, 100vh) scale(0.4304);
  }
}

.snow:nth-child(115) {
  opacity: 0.8781;
  transform: translate(11.0866vw, -10px) scale(0.0878);
  animation: fall-115 26s -15s linear infinite;
}

@keyframes fall-115 {
  48.789% {
    transform: translate(3.9236vw, 48.789vh) scale(0.0878);
  }

  to {
    transform: translate(7.5051vw, 100vh) scale(0.0878);
  }
}

.snow:nth-child(116) {
  opacity: 0.7184;
  transform: translate(73.5446vw, -10px) scale(0.4117);
  animation: fall-116 26s -14s linear infinite;
}

@keyframes fall-116 {
  75.809% {
    transform: translate(74.173vw, 75.809vh) scale(0.4117);
  }

  to {
    transform: translate(73.8588vw, 100vh) scale(0.4117);
  }
}

.snow:nth-child(117) {
  opacity: 0.7098;
  transform: translate(58.1888vw, -10px) scale(0.9282);
  animation: fall-117 14s -1s linear infinite;
}

@keyframes fall-117 {
  53.933% {
    transform: translate(65.1211vw, 53.933vh) scale(0.9282);
  }

  to {
    transform: translate(61.65495vw, 100vh) scale(0.9282);
  }
}

.snow:nth-child(118) {
  opacity: 0.4431;
  transform: translate(16.0413vw, -10px) scale(0.1604);
  animation: fall-118 28s -9s linear infinite;
}

@keyframes fall-118 {
  74.866% {
    transform: translate(11.3549vw, 74.866vh) scale(0.1604);
  }

  to {
    transform: translate(13.6981vw, 100vh) scale(0.1604);
  }
}

.snow:nth-child(119) {
  opacity: 0.5437;
  transform: translate(23.2149vw, -10px) scale(0.8597);
  animation: fall-119 13s -14s linear infinite;
}

@keyframes fall-119 {
  68.906% {
    transform: translate(19.9708vw, 68.906vh) scale(0.8597);
  }

  to {
    transform: translate(21.59285vw, 100vh) scale(0.8597);
  }
}

.snow:nth-child(120) {
  opacity: 0.8398;
  transform: translate(75.3797vw, -10px) scale(0.6864);
  animation: fall-120 19s -29s linear infinite;
}

@keyframes fall-120 {
  73.247% {
    transform: translate(78.1883vw, 73.247vh) scale(0.6864);
  }

  to {
    transform: translate(76.784vw, 100vh) scale(0.6864);
  }
}




/* 

@-webkit-keyframes move {
  100% {
    transform: translate3d(0, 0, -8000px);
  }
}
@keyframes move {
  100% {
    transform: translate3d(0, 0, -8000px);
  }
}
.b_container {
  position: relative;
  width: 100%;
  min-height: 100%;
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
}

.bubble-wrap {
  margin: 0 auto;
  width: 500px;
  height: 500px;
  width: 100%;
  transform-style: preserve-3d;
  transform-origin: center center;
  perspective: 700px;
  overflow: hidden;
}

.bubble {
  position: absolute;
  background: black;
  opacity: 0.7;
  border-radius: 50%;
  -webkit-animation: move 53s infinite;
          animation: move 53s infinite;
  margin: 0 0 0 -100px;
}

.bubble:nth-child(1) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  transform: translate3d(1864px, 924px, 903px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(2) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  transform: translate3d(1199px, 334px, 1705px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(3) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  transform: translate3d(1528px, 897px, 157px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(4) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  transform: translate3d(4956px, 624px, 1586px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(5) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  transform: translate3d(1230px, 698px, 1792px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(6) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
  transform: translate3d(1769px, 241px, 919px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(7) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -1.4s;
          animation-delay: -1.4s;
  transform: translate3d(2941px, 945px, 1492px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(8) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -1.6s;
          animation-delay: -1.6s;
  transform: translate3d(1015px, 658px, 1375px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(9) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -1.8s;
          animation-delay: -1.8s;
  transform: translate3d(653px, 966px, 457px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(10) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -2s;
          animation-delay: -2s;
  transform: translate3d(3283px, 311px, 1355px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(11) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -2.2s;
          animation-delay: -2.2s;
  transform: translate3d(3660px, 863px, 400px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(12) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -2.4s;
          animation-delay: -2.4s;
  transform: translate3d(1775px, 111px, 988px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(13) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -2.6s;
          animation-delay: -2.6s;
  transform: translate3d(2874px, 229px, 1489px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(14) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -2.8s;
          animation-delay: -2.8s;
  transform: translate3d(4306px, 743px, 640px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(15) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -3s;
          animation-delay: -3s;
  transform: translate3d(509px, 303px, 1046px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(16) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -3.2s;
          animation-delay: -3.2s;
  transform: translate3d(2280px, 596px, 124px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(17) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -3.4s;
          animation-delay: -3.4s;
  transform: translate3d(3846px, 821px, 639px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(18) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -3.6s;
          animation-delay: -3.6s;
  transform: translate3d(4341px, 599px, 964px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(19) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -3.8s;
          animation-delay: -3.8s;
  transform: translate3d(541px, 813px, 744px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(20) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -4s;
          animation-delay: -4s;
  transform: translate3d(1283px, 87px, 1832px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(21) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -4.2s;
          animation-delay: -4.2s;
  transform: translate3d(3334px, 614px, 1155px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(22) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -4.4s;
          animation-delay: -4.4s;
  transform: translate3d(3169px, 569px, 856px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(23) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -4.6s;
          animation-delay: -4.6s;
  transform: translate3d(4846px, 388px, 1082px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(24) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -4.8s;
          animation-delay: -4.8s;
  transform: translate3d(1392px, 117px, 747px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(25) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -5s;
          animation-delay: -5s;
  transform: translate3d(55px, 802px, 581px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(26) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -5.2s;
          animation-delay: -5.2s;
  transform: translate3d(2849px, 444px, 1707px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(27) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -5.4s;
          animation-delay: -5.4s;
  transform: translate3d(3628px, 325px, 1582px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(28) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -5.6s;
          animation-delay: -5.6s;
  transform: translate3d(3876px, 913px, 1319px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(29) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -5.8s;
          animation-delay: -5.8s;
  transform: translate3d(1877px, 55px, 1182px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(30) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -6s;
          animation-delay: -6s;
  transform: translate3d(4635px, 705px, 913px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(31) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -6.2s;
          animation-delay: -6.2s;
  transform: translate3d(743px, 177px, 864px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(32) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -6.4s;
          animation-delay: -6.4s;
  transform: translate3d(58px, 702px, 280px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(33) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -6.6s;
          animation-delay: -6.6s;
  transform: translate3d(796px, 614px, 39px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(34) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -6.8s;
          animation-delay: -6.8s;
  transform: translate3d(2787px, 277px, 429px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(35) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -7s;
          animation-delay: -7s;
  transform: translate3d(1974px, 209px, 848px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(36) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -7.2s;
          animation-delay: -7.2s;
  transform: translate3d(4038px, 165px, 1092px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(37) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -7.4s;
          animation-delay: -7.4s;
  transform: translate3d(1608px, 989px, 1304px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(38) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -7.6s;
          animation-delay: -7.6s;
  transform: translate3d(513px, 858px, 1275px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(39) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -7.8s;
          animation-delay: -7.8s;
  transform: translate3d(4609px, 540px, 199px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(40) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -8s;
          animation-delay: -8s;
  transform: translate3d(1369px, 807px, 1237px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(41) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -8.2s;
          animation-delay: -8.2s;
  transform: translate3d(3435px, 69px, 1179px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(42) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -8.4s;
          animation-delay: -8.4s;
  transform: translate3d(2707px, 410px, 247px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(43) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -8.6s;
          animation-delay: -8.6s;
  transform: translate3d(4582px, 452px, 1147px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(44) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -8.8s;
          animation-delay: -8.8s;
  transform: translate3d(752px, 190px, 833px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(45) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -9s;
          animation-delay: -9s;
  transform: translate3d(4178px, 174px, 1051px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(46) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -9.2s;
          animation-delay: -9.2s;
  transform: translate3d(2548px, 522px, 892px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(47) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -9.4s;
          animation-delay: -9.4s;
  transform: translate3d(1874px, 375px, 344px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(48) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -9.6s;
          animation-delay: -9.6s;
  transform: translate3d(2142px, 835px, 398px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(49) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -9.8s;
          animation-delay: -9.8s;
  transform: translate3d(827px, 862px, 745px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(50) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -10s;
          animation-delay: -10s;
  transform: translate3d(3344px, 191px, 1627px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(51) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -10.2s;
          animation-delay: -10.2s;
  transform: translate3d(838px, 792px, 1502px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(52) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -10.4s;
          animation-delay: -10.4s;
  transform: translate3d(4180px, 676px, 345px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(53) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -10.6s;
          animation-delay: -10.6s;
  transform: translate3d(1358px, 588px, 1935px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(54) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -10.8s;
          animation-delay: -10.8s;
  transform: translate3d(2732px, 707px, 256px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(55) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -11s;
          animation-delay: -11s;
  transform: translate3d(3569px, 360px, 537px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(56) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -11.2s;
          animation-delay: -11.2s;
  transform: translate3d(4368px, 226px, 16px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(57) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -11.4s;
          animation-delay: -11.4s;
  transform: translate3d(3514px, 319px, 609px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(58) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -11.6s;
          animation-delay: -11.6s;
  transform: translate3d(2375px, 326px, 129px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(59) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -11.8s;
          animation-delay: -11.8s;
  transform: translate3d(389px, 80px, 171px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(60) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -12s;
          animation-delay: -12s;
  transform: translate3d(1974px, 133px, 493px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(61) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -12.2s;
          animation-delay: -12.2s;
  transform: translate3d(3686px, 946px, 1104px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(62) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -12.4s;
          animation-delay: -12.4s;
  transform: translate3d(348px, 292px, 1828px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(63) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -12.6s;
          animation-delay: -12.6s;
  transform: translate3d(1065px, 30px, 702px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(64) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -12.8s;
          animation-delay: -12.8s;
  transform: translate3d(1473px, 462px, 1295px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(65) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -13s;
          animation-delay: -13s;
  transform: translate3d(4855px, 441px, 1474px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(66) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -13.2s;
          animation-delay: -13.2s;
  transform: translate3d(2836px, 195px, 1231px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(67) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -13.4s;
          animation-delay: -13.4s;
  transform: translate3d(777px, 752px, 518px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(68) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -13.6s;
          animation-delay: -13.6s;
  transform: translate3d(3212px, 802px, 374px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(69) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -13.8s;
          animation-delay: -13.8s;
  transform: translate3d(4455px, 556px, 234px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(70) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -14s;
          animation-delay: -14s;
  transform: translate3d(1456px, 158px, 1759px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(71) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -14.2s;
          animation-delay: -14.2s;
  transform: translate3d(2749px, 996px, 1387px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(72) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -14.4s;
          animation-delay: -14.4s;
  transform: translate3d(4722px, 303px, 4px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(73) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -14.6s;
          animation-delay: -14.6s;
  transform: translate3d(4982px, 671px, 605px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(74) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -14.8s;
          animation-delay: -14.8s;
  transform: translate3d(3338px, 179px, 171px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(75) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -15s;
          animation-delay: -15s;
  transform: translate3d(411px, 992px, 1187px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(76) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -15.2s;
          animation-delay: -15.2s;
  transform: translate3d(1777px, 428px, 1713px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(77) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -15.4s;
          animation-delay: -15.4s;
  transform: translate3d(4661px, 200px, 843px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(78) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -15.6s;
          animation-delay: -15.6s;
  transform: translate3d(22px, 52px, 1390px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(79) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -15.8s;
          animation-delay: -15.8s;
  transform: translate3d(3128px, 658px, 1956px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(80) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -16s;
          animation-delay: -16s;
  transform: translate3d(2357px, 570px, 1746px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(81) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -16.2s;
          animation-delay: -16.2s;
  transform: translate3d(3993px, 435px, 1249px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(82) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -16.4s;
          animation-delay: -16.4s;
  transform: translate3d(3594px, 462px, 742px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(83) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -16.6s;
          animation-delay: -16.6s;
  transform: translate3d(2054px, 532px, 361px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(84) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -16.8s;
          animation-delay: -16.8s;
  transform: translate3d(140px, 438px, 1643px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(85) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -17s;
          animation-delay: -17s;
  transform: translate3d(1965px, 685px, 1829px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(86) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -17.2s;
          animation-delay: -17.2s;
  transform: translate3d(3567px, 504px, 1610px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(87) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -17.4s;
          animation-delay: -17.4s;
  transform: translate3d(4063px, 557px, 428px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(88) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -17.6s;
          animation-delay: -17.6s;
  transform: translate3d(3078px, 503px, 1353px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(89) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -17.8s;
          animation-delay: -17.8s;
  transform: translate3d(4834px, 350px, 1289px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(90) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -18s;
          animation-delay: -18s;
  transform: translate3d(2383px, 913px, 366px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(91) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -18.2s;
          animation-delay: -18.2s;
  transform: translate3d(4428px, 179px, 303px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(92) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -18.4s;
          animation-delay: -18.4s;
  transform: translate3d(1474px, 658px, 779px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(93) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -18.6s;
          animation-delay: -18.6s;
  transform: translate3d(2287px, 654px, 1092px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(94) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -18.8s;
          animation-delay: -18.8s;
  transform: translate3d(3324px, 810px, 1801px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(95) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -19s;
          animation-delay: -19s;
  transform: translate3d(1501px, 414px, 1079px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(96) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -19.2s;
          animation-delay: -19.2s;
  transform: translate3d(485px, 409px, 435px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(97) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -19.4s;
          animation-delay: -19.4s;
  transform: translate3d(852px, 835px, 1534px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(98) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -19.6s;
          animation-delay: -19.6s;
  transform: translate3d(4116px, 509px, 238px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(99) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -19.8s;
          animation-delay: -19.8s;
  transform: translate3d(1903px, 29px, 1154px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(100) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -20s;
          animation-delay: -20s;
  transform: translate3d(2613px, 663px, 913px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(101) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -20.2s;
          animation-delay: -20.2s;
  transform: translate3d(1497px, 962px, 1675px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(102) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -20.4s;
          animation-delay: -20.4s;
  transform: translate3d(4674px, 138px, 970px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(103) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -20.6s;
          animation-delay: -20.6s;
  transform: translate3d(494px, 234px, 1271px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(104) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -20.8s;
          animation-delay: -20.8s;
  transform: translate3d(3414px, 933px, 941px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(105) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -21s;
          animation-delay: -21s;
  transform: translate3d(1634px, 354px, 1563px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(106) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -21.2s;
          animation-delay: -21.2s;
  transform: translate3d(4342px, 801px, 19px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(107) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -21.4s;
          animation-delay: -21.4s;
  transform: translate3d(3475px, 713px, 1402px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(108) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -21.6s;
          animation-delay: -21.6s;
  transform: translate3d(1210px, 134px, 1197px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(109) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -21.8s;
          animation-delay: -21.8s;
  transform: translate3d(1425px, 768px, 1352px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(110) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -22s;
          animation-delay: -22s;
  transform: translate3d(1069px, 438px, 184px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(111) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -22.2s;
          animation-delay: -22.2s;
  transform: translate3d(4708px, 224px, 480px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(112) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -22.4s;
          animation-delay: -22.4s;
  transform: translate3d(1885px, 103px, 746px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(113) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -22.6s;
          animation-delay: -22.6s;
  transform: translate3d(4553px, 133px, 807px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(114) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -22.8s;
          animation-delay: -22.8s;
  transform: translate3d(4312px, 955px, 750px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(115) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -23s;
          animation-delay: -23s;
  transform: translate3d(728px, 75px, 748px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(116) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -23.2s;
          animation-delay: -23.2s;
  transform: translate3d(3623px, 118px, 892px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(117) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -23.4s;
          animation-delay: -23.4s;
  transform: translate3d(603px, 800px, 992px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(118) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -23.6s;
          animation-delay: -23.6s;
  transform: translate3d(1112px, 855px, 1815px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(119) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -23.8s;
          animation-delay: -23.8s;
  transform: translate3d(3304px, 553px, 93px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(120) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -24s;
          animation-delay: -24s;
  transform: translate3d(4701px, 952px, 1362px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(121) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -24.2s;
          animation-delay: -24.2s;
  transform: translate3d(1996px, 179px, 1951px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(122) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -24.4s;
          animation-delay: -24.4s;
  transform: translate3d(481px, 480px, 1534px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(123) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -24.6s;
          animation-delay: -24.6s;
  transform: translate3d(793px, 844px, 1401px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(124) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -24.8s;
          animation-delay: -24.8s;
  transform: translate3d(3037px, 611px, 286px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(125) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -25s;
          animation-delay: -25s;
  transform: translate3d(2447px, 966px, 903px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(126) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -25.2s;
          animation-delay: -25.2s;
  transform: translate3d(3799px, 56px, 560px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(127) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -25.4s;
          animation-delay: -25.4s;
  transform: translate3d(2175px, 46px, 1645px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(128) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -25.6s;
          animation-delay: -25.6s;
  transform: translate3d(2865px, 895px, 268px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(129) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -25.8s;
          animation-delay: -25.8s;
  transform: translate3d(1847px, 549px, 1130px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(130) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -26s;
          animation-delay: -26s;
  transform: translate3d(3076px, 115px, 1514px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(131) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -26.2s;
          animation-delay: -26.2s;
  transform: translate3d(165px, 875px, 384px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(132) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -26.4s;
          animation-delay: -26.4s;
  transform: translate3d(885px, 871px, 1368px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(133) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -26.6s;
          animation-delay: -26.6s;
  transform: translate3d(1628px, 930px, 1740px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(134) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -26.8s;
          animation-delay: -26.8s;
  transform: translate3d(3640px, 613px, 1141px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(135) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -27s;
          animation-delay: -27s;
  transform: translate3d(966px, 184px, 548px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(136) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -27.2s;
          animation-delay: -27.2s;
  transform: translate3d(4039px, 625px, 1518px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(137) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -27.4s;
          animation-delay: -27.4s;
  transform: translate3d(3521px, 549px, 1421px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(138) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -27.6s;
          animation-delay: -27.6s;
  transform: translate3d(244px, 159px, 1499px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(139) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -27.8s;
          animation-delay: -27.8s;
  transform: translate3d(4121px, 180px, 1356px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(140) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -28s;
          animation-delay: -28s;
  transform: translate3d(4984px, 771px, 543px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(141) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -28.2s;
          animation-delay: -28.2s;
  transform: translate3d(1287px, 553px, 963px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(142) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -28.4s;
          animation-delay: -28.4s;
  transform: translate3d(1066px, 147px, 724px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(143) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -28.6s;
          animation-delay: -28.6s;
  transform: translate3d(3039px, 591px, 241px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(144) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -28.8s;
          animation-delay: -28.8s;
  transform: translate3d(353px, 191px, 227px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(145) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -29s;
          animation-delay: -29s;
  transform: translate3d(4596px, 827px, 1327px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(146) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -29.2s;
          animation-delay: -29.2s;
  transform: translate3d(2478px, 293px, 447px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(147) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -29.4s;
          animation-delay: -29.4s;
  transform: translate3d(1132px, 761px, 164px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(148) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -29.6s;
          animation-delay: -29.6s;
  transform: translate3d(809px, 458px, 736px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(149) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -29.8s;
          animation-delay: -29.8s;
  transform: translate3d(2707px, 187px, 1820px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(150) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -30s;
          animation-delay: -30s;
  transform: translate3d(1376px, 211px, 1788px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(151) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -30.2s;
          animation-delay: -30.2s;
  transform: translate3d(1013px, 648px, 1310px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(152) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -30.4s;
          animation-delay: -30.4s;
  transform: translate3d(142px, 118px, 1987px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(153) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -30.6s;
          animation-delay: -30.6s;
  transform: translate3d(262px, 950px, 1651px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(154) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -30.8s;
          animation-delay: -30.8s;
  transform: translate3d(349px, 432px, 20px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(155) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -31s;
          animation-delay: -31s;
  transform: translate3d(2125px, 628px, 1976px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(156) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -31.2s;
          animation-delay: -31.2s;
  transform: translate3d(3713px, 69px, 1074px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(157) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -31.4s;
          animation-delay: -31.4s;
  transform: translate3d(113px, 818px, 1174px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(158) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -31.6s;
          animation-delay: -31.6s;
  transform: translate3d(2315px, 922px, 1226px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(159) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -31.8s;
          animation-delay: -31.8s;
  transform: translate3d(4894px, 32px, 720px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(160) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -32s;
          animation-delay: -32s;
  transform: translate3d(3836px, 933px, 404px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(161) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -32.2s;
          animation-delay: -32.2s;
  transform: translate3d(3548px, 352px, 1882px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(162) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -32.4s;
          animation-delay: -32.4s;
  transform: translate3d(214px, 258px, 494px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(163) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -32.6s;
          animation-delay: -32.6s;
  transform: translate3d(1713px, 584px, 1884px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(164) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -32.8s;
          animation-delay: -32.8s;
  transform: translate3d(4880px, 462px, 648px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(165) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -33s;
          animation-delay: -33s;
  transform: translate3d(4060px, 455px, 1292px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(166) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -33.2s;
          animation-delay: -33.2s;
  transform: translate3d(2718px, 417px, 818px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(167) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -33.4s;
          animation-delay: -33.4s;
  transform: translate3d(3538px, 656px, 683px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(168) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -33.6s;
          animation-delay: -33.6s;
  transform: translate3d(1520px, 816px, 1476px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(169) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -33.8s;
          animation-delay: -33.8s;
  transform: translate3d(3779px, 281px, 1346px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(170) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -34s;
          animation-delay: -34s;
  transform: translate3d(1034px, 425px, 291px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(171) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -34.2s;
          animation-delay: -34.2s;
  transform: translate3d(2150px, 832px, 1609px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(172) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -34.4s;
          animation-delay: -34.4s;
  transform: translate3d(3208px, 51px, 1624px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(173) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -34.6s;
          animation-delay: -34.6s;
  transform: translate3d(859px, 207px, 1849px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(174) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -34.8s;
          animation-delay: -34.8s;
  transform: translate3d(3088px, 969px, 1867px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(175) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -35s;
          animation-delay: -35s;
  transform: translate3d(3960px, 256px, 890px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(176) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -35.2s;
          animation-delay: -35.2s;
  transform: translate3d(4157px, 592px, 26px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(177) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -35.4s;
          animation-delay: -35.4s;
  transform: translate3d(2030px, 981px, 1974px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(178) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -35.6s;
          animation-delay: -35.6s;
  transform: translate3d(915px, 668px, 675px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(179) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -35.8s;
          animation-delay: -35.8s;
  transform: translate3d(1763px, 227px, 627px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(180) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -36s;
          animation-delay: -36s;
  transform: translate3d(1661px, 367px, 1649px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(181) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -36.2s;
          animation-delay: -36.2s;
  transform: translate3d(1669px, 319px, 442px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(182) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -36.4s;
          animation-delay: -36.4s;
  transform: translate3d(2855px, 533px, 764px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(183) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -36.6s;
          animation-delay: -36.6s;
  transform: translate3d(958px, 930px, 898px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(184) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -36.8s;
          animation-delay: -36.8s;
  transform: translate3d(3918px, 809px, 1601px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(185) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -37s;
          animation-delay: -37s;
  transform: translate3d(2170px, 920px, 476px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(186) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -37.2s;
          animation-delay: -37.2s;
  transform: translate3d(4937px, 350px, 1957px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(187) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -37.4s;
          animation-delay: -37.4s;
  transform: translate3d(4659px, 315px, 1778px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(188) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -37.6s;
          animation-delay: -37.6s;
  transform: translate3d(1396px, 19px, 149px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(189) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -37.8s;
          animation-delay: -37.8s;
  transform: translate3d(1395px, 222px, 277px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(190) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -38s;
          animation-delay: -38s;
  transform: translate3d(4003px, 572px, 988px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(191) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -38.2s;
          animation-delay: -38.2s;
  transform: translate3d(3789px, 368px, 1916px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(192) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -38.4s;
          animation-delay: -38.4s;
  transform: translate3d(3203px, 899px, 1430px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(193) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -38.6s;
          animation-delay: -38.6s;
  transform: translate3d(2223px, 863px, 1273px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(194) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -38.8s;
          animation-delay: -38.8s;
  transform: translate3d(4144px, 264px, 1067px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(195) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -39s;
          animation-delay: -39s;
  transform: translate3d(1430px, 880px, 656px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(196) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -39.2s;
          animation-delay: -39.2s;
  transform: translate3d(2203px, 249px, 118px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(197) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -39.4s;
          animation-delay: -39.4s;
  transform: translate3d(4541px, 926px, 916px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(198) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -39.6s;
          animation-delay: -39.6s;
  transform: translate3d(156px, 305px, 791px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(199) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -39.8s;
          animation-delay: -39.8s;
  transform: translate3d(3699px, 603px, 297px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(200) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -40s;
          animation-delay: -40s;
  transform: translate3d(3706px, 748px, 139px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(201) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -40.2s;
          animation-delay: -40.2s;
  transform: translate3d(735px, 52px, 1683px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(202) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -40.4s;
          animation-delay: -40.4s;
  transform: translate3d(4339px, 957px, 1094px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(203) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -40.6s;
          animation-delay: -40.6s;
  transform: translate3d(4638px, 783px, 518px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(204) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -40.8s;
          animation-delay: -40.8s;
  transform: translate3d(3847px, 668px, 150px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(205) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -41s;
          animation-delay: -41s;
  transform: translate3d(3020px, 51px, 55px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(206) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -41.2s;
          animation-delay: -41.2s;
  transform: translate3d(3708px, 480px, 1062px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(207) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -41.4s;
          animation-delay: -41.4s;
  transform: translate3d(3138px, 470px, 488px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(208) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -41.6s;
          animation-delay: -41.6s;
  transform: translate3d(3036px, 172px, 262px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(209) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -41.8s;
          animation-delay: -41.8s;
  transform: translate3d(436px, 457px, 1781px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(210) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -42s;
          animation-delay: -42s;
  transform: translate3d(883px, 82px, 950px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(211) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -42.2s;
          animation-delay: -42.2s;
  transform: translate3d(85px, 1000px, 306px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(212) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -42.4s;
          animation-delay: -42.4s;
  transform: translate3d(3415px, 216px, 1153px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(213) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -42.6s;
          animation-delay: -42.6s;
  transform: translate3d(3853px, 282px, 1877px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(214) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -42.8s;
          animation-delay: -42.8s;
  transform: translate3d(2089px, 153px, 689px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(215) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -43s;
          animation-delay: -43s;
  transform: translate3d(3820px, 532px, 480px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(216) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -43.2s;
          animation-delay: -43.2s;
  transform: translate3d(484px, 543px, 545px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(217) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -43.4s;
          animation-delay: -43.4s;
  transform: translate3d(1418px, 645px, 500px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(218) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -43.6s;
          animation-delay: -43.6s;
  transform: translate3d(3910px, 117px, 1428px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(219) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -43.8s;
          animation-delay: -43.8s;
  transform: translate3d(4608px, 538px, 1212px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(220) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -44s;
          animation-delay: -44s;
  transform: translate3d(4921px, 963px, 289px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(221) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -44.2s;
          animation-delay: -44.2s;
  transform: translate3d(1676px, 514px, 1091px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(222) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -44.4s;
          animation-delay: -44.4s;
  transform: translate3d(1002px, 424px, 152px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(223) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -44.6s;
          animation-delay: -44.6s;
  transform: translate3d(1852px, 719px, 1628px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(224) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -44.8s;
          animation-delay: -44.8s;
  transform: translate3d(1988px, 243px, 1646px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(225) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -45s;
          animation-delay: -45s;
  transform: translate3d(3789px, 807px, 1354px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(226) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -45.2s;
          animation-delay: -45.2s;
  transform: translate3d(2295px, 186px, 4px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(227) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -45.4s;
          animation-delay: -45.4s;
  transform: translate3d(4359px, 481px, 764px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(228) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -45.6s;
          animation-delay: -45.6s;
  transform: translate3d(1103px, 970px, 1380px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(229) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -45.8s;
          animation-delay: -45.8s;
  transform: translate3d(296px, 883px, 1595px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(230) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -46s;
          animation-delay: -46s;
  transform: translate3d(3555px, 891px, 1922px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(231) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -46.2s;
          animation-delay: -46.2s;
  transform: translate3d(4567px, 371px, 1913px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(232) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -46.4s;
          animation-delay: -46.4s;
  transform: translate3d(2200px, 787px, 778px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(233) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -46.6s;
          animation-delay: -46.6s;
  transform: translate3d(3471px, 826px, 1195px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(234) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -46.8s;
          animation-delay: -46.8s;
  transform: translate3d(226px, 231px, 1705px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(235) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -47s;
          animation-delay: -47s;
  transform: translate3d(2643px, 237px, 1947px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(236) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -47.2s;
          animation-delay: -47.2s;
  transform: translate3d(3048px, 936px, 749px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(237) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -47.4s;
          animation-delay: -47.4s;
  transform: translate3d(256px, 343px, 506px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(238) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -47.6s;
          animation-delay: -47.6s;
  transform: translate3d(4676px, 363px, 289px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(239) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -47.8s;
          animation-delay: -47.8s;
  transform: translate3d(2255px, 838px, 893px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(240) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -48s;
          animation-delay: -48s;
  transform: translate3d(1454px, 251px, 1198px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(241) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -48.2s;
          animation-delay: -48.2s;
  transform: translate3d(192px, 83px, 569px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(242) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -48.4s;
          animation-delay: -48.4s;
  transform: translate3d(4422px, 855px, 1030px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(243) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -48.6s;
          animation-delay: -48.6s;
  transform: translate3d(1222px, 949px, 724px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(244) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -48.8s;
          animation-delay: -48.8s;
  transform: translate3d(1343px, 820px, 431px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(245) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -49s;
          animation-delay: -49s;
  transform: translate3d(409px, 22px, 415px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(246) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -49.2s;
          animation-delay: -49.2s;
  transform: translate3d(1278px, 523px, 1239px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(247) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -49.4s;
          animation-delay: -49.4s;
  transform: translate3d(320px, 546px, 1339px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(248) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -49.6s;
          animation-delay: -49.6s;
  transform: translate3d(4377px, 830px, 1685px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(249) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -49.8s;
          animation-delay: -49.8s;
  transform: translate3d(1002px, 760px, 1377px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(250) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -50s;
          animation-delay: -50s;
  transform: translate3d(10px, 878px, 300px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(251) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -50.2s;
          animation-delay: -50.2s;
  transform: translate3d(3556px, 661px, 1024px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(252) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -50.4s;
          animation-delay: -50.4s;
  transform: translate3d(3453px, 751px, 526px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(253) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -50.6s;
          animation-delay: -50.6s;
  transform: translate3d(3991px, 122px, 868px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(254) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -50.8s;
          animation-delay: -50.8s;
  transform: translate3d(2512px, 442px, 865px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(255) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -51s;
          animation-delay: -51s;
  transform: translate3d(2814px, 12px, 723px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(256) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -51.2s;
          animation-delay: -51.2s;
  transform: translate3d(268px, 752px, 237px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(257) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -51.4s;
          animation-delay: -51.4s;
  transform: translate3d(4123px, 819px, 1290px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(258) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -51.6s;
          animation-delay: -51.6s;
  transform: translate3d(677px, 367px, 1253px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(259) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -51.8s;
          animation-delay: -51.8s;
  transform: translate3d(3782px, 922px, 401px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(260) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -52s;
          animation-delay: -52s;
  transform: translate3d(3216px, 11px, 1707px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(261) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -52.2s;
          animation-delay: -52.2s;
  transform: translate3d(2714px, 278px, 1849px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(262) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -52.4s;
          animation-delay: -52.4s;
  transform: translate3d(3466px, 305px, 166px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(263) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -52.6s;
          animation-delay: -52.6s;
  transform: translate3d(4238px, 428px, 1355px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(264) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -52.8s;
          animation-delay: -52.8s;
  transform: translate3d(2476px, 788px, 376px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(265) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -53s;
          animation-delay: -53s;
  transform: translate3d(4873px, 162px, 560px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(266) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -53.2s;
          animation-delay: -53.2s;
  transform: translate3d(1270px, 654px, 1935px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(267) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -53.4s;
          animation-delay: -53.4s;
  transform: translate3d(232px, 548px, 1688px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(268) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -53.6s;
          animation-delay: -53.6s;
  transform: translate3d(768px, 526px, 1679px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(269) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -53.8s;
          animation-delay: -53.8s;
  transform: translate3d(2623px, 63px, 1148px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(270) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -54s;
          animation-delay: -54s;
  transform: translate3d(1330px, 165px, 1975px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(271) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -54.2s;
          animation-delay: -54.2s;
  transform: translate3d(589px, 14px, 380px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(272) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -54.4s;
          animation-delay: -54.4s;
  transform: translate3d(2409px, 337px, 609px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(273) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -54.6s;
          animation-delay: -54.6s;
  transform: translate3d(978px, 451px, 106px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(274) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -54.8s;
          animation-delay: -54.8s;
  transform: translate3d(3359px, 532px, 46px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(275) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -55s;
          animation-delay: -55s;
  transform: translate3d(4463px, 926px, 1102px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(276) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -55.2s;
          animation-delay: -55.2s;
  transform: translate3d(4064px, 25px, 201px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(277) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -55.4s;
          animation-delay: -55.4s;
  transform: translate3d(576px, 925px, 210px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(278) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -55.6s;
          animation-delay: -55.6s;
  transform: translate3d(1200px, 436px, 854px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(279) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -55.8s;
          animation-delay: -55.8s;
  transform: translate3d(2633px, 329px, 1822px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(280) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -56s;
          animation-delay: -56s;
  transform: translate3d(198px, 358px, 1771px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(281) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -56.2s;
          animation-delay: -56.2s;
  transform: translate3d(184px, 976px, 1355px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(282) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -56.4s;
          animation-delay: -56.4s;
  transform: translate3d(2688px, 27px, 67px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(283) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -56.6s;
          animation-delay: -56.6s;
  transform: translate3d(273px, 917px, 959px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(284) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -56.8s;
          animation-delay: -56.8s;
  transform: translate3d(1519px, 386px, 1271px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(285) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -57s;
          animation-delay: -57s;
  transform: translate3d(809px, 366px, 585px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(286) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -57.2s;
          animation-delay: -57.2s;
  transform: translate3d(2541px, 839px, 1302px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(287) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -57.4s;
          animation-delay: -57.4s;
  transform: translate3d(2070px, 246px, 1791px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(288) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -57.6s;
          animation-delay: -57.6s;
  transform: translate3d(2534px, 737px, 345px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(289) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -57.8s;
          animation-delay: -57.8s;
  transform: translate3d(4136px, 277px, 1009px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(290) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -58s;
          animation-delay: -58s;
  transform: translate3d(3293px, 438px, 468px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(291) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -58.2s;
          animation-delay: -58.2s;
  transform: translate3d(4467px, 136px, 455px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(292) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -58.4s;
          animation-delay: -58.4s;
  transform: translate3d(3660px, 294px, 9px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(293) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -58.6s;
          animation-delay: -58.6s;
  transform: translate3d(3196px, 687px, 1987px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(294) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -58.8s;
          animation-delay: -58.8s;
  transform: translate3d(4715px, 205px, 1620px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(295) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -59s;
          animation-delay: -59s;
  transform: translate3d(4711px, 872px, 743px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(296) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -59.2s;
          animation-delay: -59.2s;
  transform: translate3d(4091px, 746px, 249px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(297) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -59.4s;
          animation-delay: -59.4s;
  transform: translate3d(3123px, 49px, 1905px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(298) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -59.6s;
          animation-delay: -59.6s;
  transform: translate3d(3845px, 699px, 144px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(299) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -59.8s;
          animation-delay: -59.8s;
  transform: translate3d(4054px, 309px, 439px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(300) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -60s;
          animation-delay: -60s;
  transform: translate3d(1484px, 925px, 1229px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(301) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -60.2s;
          animation-delay: -60.2s;
  transform: translate3d(3778px, 870px, 429px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(302) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -60.4s;
          animation-delay: -60.4s;
  transform: translate3d(3425px, 101px, 1027px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(303) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -60.6s;
          animation-delay: -60.6s;
  transform: translate3d(741px, 336px, 1052px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(304) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -60.8s;
          animation-delay: -60.8s;
  transform: translate3d(2280px, 724px, 1137px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(305) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -61s;
          animation-delay: -61s;
  transform: translate3d(3835px, 968px, 567px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(306) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -61.2s;
          animation-delay: -61.2s;
  transform: translate3d(4959px, 486px, 1532px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(307) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -61.4s;
          animation-delay: -61.4s;
  transform: translate3d(4668px, 58px, 1135px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(308) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -61.6s;
          animation-delay: -61.6s;
  transform: translate3d(1643px, 640px, 862px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(309) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -61.8s;
          animation-delay: -61.8s;
  transform: translate3d(3715px, 624px, 369px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(310) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -62s;
          animation-delay: -62s;
  transform: translate3d(136px, 274px, 548px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(311) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -62.2s;
          animation-delay: -62.2s;
  transform: translate3d(1713px, 920px, 948px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(312) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -62.4s;
          animation-delay: -62.4s;
  transform: translate3d(2218px, 922px, 893px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(313) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -62.6s;
          animation-delay: -62.6s;
  transform: translate3d(285px, 57px, 1976px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(314) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -62.8s;
          animation-delay: -62.8s;
  transform: translate3d(1721px, 644px, 1519px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(315) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -63s;
          animation-delay: -63s;
  transform: translate3d(3560px, 742px, 1309px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(316) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -63.2s;
          animation-delay: -63.2s;
  transform: translate3d(4858px, 485px, 1107px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(317) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -63.4s;
          animation-delay: -63.4s;
  transform: translate3d(4862px, 934px, 942px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(318) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -63.6s;
          animation-delay: -63.6s;
  transform: translate3d(3715px, 225px, 1105px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(319) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -63.8s;
          animation-delay: -63.8s;
  transform: translate3d(2870px, 897px, 644px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(320) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -64s;
          animation-delay: -64s;
  transform: translate3d(2861px, 537px, 600px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(321) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -64.2s;
          animation-delay: -64.2s;
  transform: translate3d(1404px, 901px, 28px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(322) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -64.4s;
          animation-delay: -64.4s;
  transform: translate3d(4607px, 53px, 310px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(323) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -64.6s;
          animation-delay: -64.6s;
  transform: translate3d(2493px, 507px, 739px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(324) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -64.8s;
          animation-delay: -64.8s;
  transform: translate3d(117px, 931px, 705px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(325) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -65s;
          animation-delay: -65s;
  transform: translate3d(843px, 820px, 1877px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(326) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -65.2s;
          animation-delay: -65.2s;
  transform: translate3d(2035px, 192px, 174px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(327) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -65.4s;
          animation-delay: -65.4s;
  transform: translate3d(2000px, 642px, 1115px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(328) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -65.6s;
          animation-delay: -65.6s;
  transform: translate3d(273px, 810px, 446px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(329) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -65.8s;
          animation-delay: -65.8s;
  transform: translate3d(1731px, 39px, 908px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(330) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -66s;
          animation-delay: -66s;
  transform: translate3d(1081px, 118px, 1290px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(331) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -66.2s;
          animation-delay: -66.2s;
  transform: translate3d(1919px, 931px, 1046px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(332) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -66.4s;
          animation-delay: -66.4s;
  transform: translate3d(3520px, 870px, 407px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(333) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -66.6s;
          animation-delay: -66.6s;
  transform: translate3d(3672px, 539px, 447px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(334) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -66.8s;
          animation-delay: -66.8s;
  transform: translate3d(1638px, 211px, 1487px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(335) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -67s;
          animation-delay: -67s;
  transform: translate3d(3264px, 358px, 1093px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(336) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -67.2s;
          animation-delay: -67.2s;
  transform: translate3d(1070px, 283px, 490px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(337) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -67.4s;
          animation-delay: -67.4s;
  transform: translate3d(792px, 30px, 1985px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(338) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -67.6s;
          animation-delay: -67.6s;
  transform: translate3d(4082px, 930px, 1339px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(339) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -67.8s;
          animation-delay: -67.8s;
  transform: translate3d(1910px, 471px, 33px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(340) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -68s;
          animation-delay: -68s;
  transform: translate3d(812px, 981px, 1748px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(341) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -68.2s;
          animation-delay: -68.2s;
  transform: translate3d(881px, 399px, 1533px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(342) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -68.4s;
          animation-delay: -68.4s;
  transform: translate3d(2126px, 119px, 702px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(343) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -68.6s;
          animation-delay: -68.6s;
  transform: translate3d(1335px, 899px, 1465px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(344) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -68.8s;
          animation-delay: -68.8s;
  transform: translate3d(3032px, 225px, 1273px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(345) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -69s;
          animation-delay: -69s;
  transform: translate3d(2219px, 848px, 1452px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(346) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -69.2s;
          animation-delay: -69.2s;
  transform: translate3d(4365px, 585px, 309px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(347) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -69.4s;
          animation-delay: -69.4s;
  transform: translate3d(3577px, 834px, 422px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(348) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -69.6s;
          animation-delay: -69.6s;
  transform: translate3d(2295px, 539px, 1597px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(349) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -69.8s;
          animation-delay: -69.8s;
  transform: translate3d(1923px, 11px, 396px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(350) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -70s;
          animation-delay: -70s;
  transform: translate3d(2355px, 152px, 405px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(351) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -70.2s;
          animation-delay: -70.2s;
  transform: translate3d(720px, 521px, 1587px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(352) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -70.4s;
          animation-delay: -70.4s;
  transform: translate3d(2832px, 496px, 1826px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(353) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -70.6s;
          animation-delay: -70.6s;
  transform: translate3d(3779px, 337px, 1683px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(354) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -70.8s;
          animation-delay: -70.8s;
  transform: translate3d(136px, 588px, 670px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(355) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -71s;
          animation-delay: -71s;
  transform: translate3d(891px, 590px, 32px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(356) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -71.2s;
          animation-delay: -71.2s;
  transform: translate3d(2151px, 281px, 476px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(357) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -71.4s;
          animation-delay: -71.4s;
  transform: translate3d(3423px, 709px, 1521px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(358) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -71.6s;
          animation-delay: -71.6s;
  transform: translate3d(3292px, 676px, 1628px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(359) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -71.8s;
          animation-delay: -71.8s;
  transform: translate3d(1977px, 512px, 1401px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(360) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -72s;
          animation-delay: -72s;
  transform: translate3d(1307px, 553px, 1627px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(361) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -72.2s;
          animation-delay: -72.2s;
  transform: translate3d(4632px, 439px, 1252px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(362) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -72.4s;
          animation-delay: -72.4s;
  transform: translate3d(2352px, 386px, 105px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(363) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -72.6s;
          animation-delay: -72.6s;
  transform: translate3d(3225px, 194px, 1376px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(364) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -72.8s;
          animation-delay: -72.8s;
  transform: translate3d(4019px, 800px, 1003px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(365) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -73s;
          animation-delay: -73s;
  transform: translate3d(4398px, 12px, 1202px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(366) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -73.2s;
          animation-delay: -73.2s;
  transform: translate3d(3090px, 788px, 1267px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(367) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -73.4s;
          animation-delay: -73.4s;
  transform: translate3d(269px, 656px, 1147px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(368) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -73.6s;
          animation-delay: -73.6s;
  transform: translate3d(794px, 600px, 571px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(369) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -73.8s;
          animation-delay: -73.8s;
  transform: translate3d(4117px, 856px, 581px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(370) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -74s;
          animation-delay: -74s;
  transform: translate3d(4629px, 293px, 397px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(371) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -74.2s;
          animation-delay: -74.2s;
  transform: translate3d(2692px, 319px, 1587px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(372) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -74.4s;
          animation-delay: -74.4s;
  transform: translate3d(719px, 197px, 616px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(373) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -74.6s;
          animation-delay: -74.6s;
  transform: translate3d(935px, 988px, 991px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(374) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -74.8s;
          animation-delay: -74.8s;
  transform: translate3d(1899px, 393px, 1791px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(375) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -75s;
          animation-delay: -75s;
  transform: translate3d(4741px, 74px, 107px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(376) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -75.2s;
          animation-delay: -75.2s;
  transform: translate3d(2557px, 390px, 1441px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(377) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -75.4s;
          animation-delay: -75.4s;
  transform: translate3d(3750px, 356px, 6px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(378) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -75.6s;
          animation-delay: -75.6s;
  transform: translate3d(2545px, 286px, 1757px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(379) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -75.8s;
          animation-delay: -75.8s;
  transform: translate3d(254px, 57px, 632px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(380) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -76s;
          animation-delay: -76s;
  transform: translate3d(2667px, 660px, 15px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(381) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -76.2s;
          animation-delay: -76.2s;
  transform: translate3d(1070px, 439px, 1688px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(382) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -76.4s;
          animation-delay: -76.4s;
  transform: translate3d(4295px, 352px, 1739px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(383) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -76.6s;
          animation-delay: -76.6s;
  transform: translate3d(4237px, 356px, 1502px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(384) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -76.8s;
          animation-delay: -76.8s;
  transform: translate3d(2400px, 926px, 475px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(385) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -77s;
          animation-delay: -77s;
  transform: translate3d(1102px, 429px, 392px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(386) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -77.2s;
          animation-delay: -77.2s;
  transform: translate3d(3957px, 286px, 1279px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(387) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -77.4s;
          animation-delay: -77.4s;
  transform: translate3d(2413px, 156px, 1315px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(388) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -77.6s;
          animation-delay: -77.6s;
  transform: translate3d(3690px, 109px, 225px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(389) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -77.8s;
          animation-delay: -77.8s;
  transform: translate3d(3808px, 732px, 454px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(390) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -78s;
          animation-delay: -78s;
  transform: translate3d(493px, 491px, 343px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(391) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -78.2s;
          animation-delay: -78.2s;
  transform: translate3d(3486px, 428px, 649px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(392) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -78.4s;
          animation-delay: -78.4s;
  transform: translate3d(1228px, 454px, 171px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(393) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -78.6s;
          animation-delay: -78.6s;
  transform: translate3d(3465px, 884px, 211px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(394) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -78.8s;
          animation-delay: -78.8s;
  transform: translate3d(4427px, 388px, 1539px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(395) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -79s;
          animation-delay: -79s;
  transform: translate3d(3101px, 307px, 600px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(396) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -79.2s;
          animation-delay: -79.2s;
  transform: translate3d(625px, 881px, 1997px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(397) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -79.4s;
          animation-delay: -79.4s;
  transform: translate3d(4709px, 595px, 952px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(398) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -79.6s;
          animation-delay: -79.6s;
  transform: translate3d(99px, 946px, 1547px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(399) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -79.8s;
          animation-delay: -79.8s;
  transform: translate3d(2193px, 631px, 1760px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(400) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -80s;
          animation-delay: -80s;
  transform: translate3d(2990px, 446px, 493px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(401) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -80.2s;
          animation-delay: -80.2s;
  transform: translate3d(1436px, 596px, 150px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(402) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -80.4s;
          animation-delay: -80.4s;
  transform: translate3d(1463px, 575px, 529px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(403) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -80.6s;
          animation-delay: -80.6s;
  transform: translate3d(1463px, 454px, 917px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(404) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -80.8s;
          animation-delay: -80.8s;
  transform: translate3d(4147px, 218px, 562px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(405) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -81s;
          animation-delay: -81s;
  transform: translate3d(646px, 477px, 1060px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(406) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -81.2s;
          animation-delay: -81.2s;
  transform: translate3d(3972px, 591px, 602px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(407) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -81.4s;
          animation-delay: -81.4s;
  transform: translate3d(2021px, 805px, 620px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(408) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -81.6s;
          animation-delay: -81.6s;
  transform: translate3d(2443px, 251px, 1572px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(409) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -81.8s;
          animation-delay: -81.8s;
  transform: translate3d(3840px, 40px, 622px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(410) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -82s;
          animation-delay: -82s;
  transform: translate3d(4683px, 462px, 574px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(411) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -82.2s;
          animation-delay: -82.2s;
  transform: translate3d(515px, 336px, 494px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(412) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -82.4s;
          animation-delay: -82.4s;
  transform: translate3d(3113px, 538px, 397px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(413) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -82.6s;
          animation-delay: -82.6s;
  transform: translate3d(598px, 698px, 1887px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(414) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -82.8s;
          animation-delay: -82.8s;
  transform: translate3d(2732px, 372px, 1004px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(415) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -83s;
          animation-delay: -83s;
  transform: translate3d(2215px, 221px, 1888px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(416) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -83.2s;
          animation-delay: -83.2s;
  transform: translate3d(4969px, 932px, 1945px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(417) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -83.4s;
          animation-delay: -83.4s;
  transform: translate3d(1717px, 88px, 788px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(418) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -83.6s;
          animation-delay: -83.6s;
  transform: translate3d(4519px, 732px, 1335px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(419) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -83.8s;
          animation-delay: -83.8s;
  transform: translate3d(189px, 241px, 469px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(420) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -84s;
          animation-delay: -84s;
  transform: translate3d(638px, 80px, 945px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(421) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -84.2s;
          animation-delay: -84.2s;
  transform: translate3d(912px, 918px, 425px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(422) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -84.4s;
          animation-delay: -84.4s;
  transform: translate3d(114px, 652px, 1465px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(423) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -84.6s;
          animation-delay: -84.6s;
  transform: translate3d(2011px, 271px, 1476px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(424) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -84.8s;
          animation-delay: -84.8s;
  transform: translate3d(4736px, 538px, 1542px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(425) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -85s;
          animation-delay: -85s;
  transform: translate3d(843px, 69px, 1573px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(426) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -85.2s;
          animation-delay: -85.2s;
  transform: translate3d(1525px, 47px, 377px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(427) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -85.4s;
          animation-delay: -85.4s;
  transform: translate3d(420px, 1000px, 1029px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(428) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -85.6s;
          animation-delay: -85.6s;
  transform: translate3d(4927px, 612px, 256px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(429) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -85.8s;
          animation-delay: -85.8s;
  transform: translate3d(2328px, 650px, 1384px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(430) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -86s;
          animation-delay: -86s;
  transform: translate3d(1843px, 881px, 25px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(431) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -86.2s;
          animation-delay: -86.2s;
  transform: translate3d(4284px, 487px, 1358px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(432) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -86.4s;
          animation-delay: -86.4s;
  transform: translate3d(863px, 359px, 1729px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(433) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -86.6s;
          animation-delay: -86.6s;
  transform: translate3d(720px, 70px, 709px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(434) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -86.8s;
          animation-delay: -86.8s;
  transform: translate3d(4946px, 184px, 503px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(435) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -87s;
          animation-delay: -87s;
  transform: translate3d(4361px, 931px, 462px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(436) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -87.2s;
          animation-delay: -87.2s;
  transform: translate3d(825px, 869px, 767px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(437) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -87.4s;
          animation-delay: -87.4s;
  transform: translate3d(2291px, 991px, 1408px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(438) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -87.6s;
          animation-delay: -87.6s;
  transform: translate3d(3606px, 52px, 1496px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(439) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -87.8s;
          animation-delay: -87.8s;
  transform: translate3d(2237px, 497px, 727px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(440) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -88s;
          animation-delay: -88s;
  transform: translate3d(4465px, 178px, 685px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(441) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -88.2s;
          animation-delay: -88.2s;
  transform: translate3d(749px, 828px, 451px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(442) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -88.4s;
          animation-delay: -88.4s;
  transform: translate3d(3366px, 194px, 1471px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(443) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -88.6s;
          animation-delay: -88.6s;
  transform: translate3d(891px, 61px, 746px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(444) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -88.8s;
          animation-delay: -88.8s;
  transform: translate3d(1895px, 30px, 848px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(445) {
  height: 7px;
  width: 7px;
  -webkit-animation-delay: -89s;
          animation-delay: -89s;
  transform: translate3d(2634px, 170px, 1128px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(446) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -89.2s;
          animation-delay: -89.2s;
  transform: translate3d(4359px, 541px, 1236px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(447) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -89.4s;
          animation-delay: -89.4s;
  transform: translate3d(3993px, 150px, 767px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(448) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -89.6s;
          animation-delay: -89.6s;
  transform: translate3d(4426px, 118px, 848px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(449) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -89.8s;
          animation-delay: -89.8s;
  transform: translate3d(4694px, 680px, 444px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(450) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -90s;
          animation-delay: -90s;
  transform: translate3d(2223px, 824px, 663px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(451) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -90.2s;
          animation-delay: -90.2s;
  transform: translate3d(4935px, 841px, 1996px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(452) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -90.4s;
          animation-delay: -90.4s;
  transform: translate3d(571px, 403px, 993px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(453) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -90.6s;
          animation-delay: -90.6s;
  transform: translate3d(165px, 470px, 708px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(454) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -90.8s;
          animation-delay: -90.8s;
  transform: translate3d(1291px, 400px, 1064px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(455) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -91s;
          animation-delay: -91s;
  transform: translate3d(2186px, 456px, 1649px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(456) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -91.2s;
          animation-delay: -91.2s;
  transform: translate3d(1577px, 861px, 399px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(457) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -91.4s;
          animation-delay: -91.4s;
  transform: translate3d(2455px, 998px, 1670px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(458) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -91.6s;
          animation-delay: -91.6s;
  transform: translate3d(2167px, 756px, 8px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(459) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -91.8s;
          animation-delay: -91.8s;
  transform: translate3d(4073px, 869px, 404px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(460) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -92s;
          animation-delay: -92s;
  transform: translate3d(4651px, 59px, 1799px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(461) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -92.2s;
          animation-delay: -92.2s;
  transform: translate3d(40px, 341px, 861px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(462) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -92.4s;
          animation-delay: -92.4s;
  transform: translate3d(3105px, 999px, 1355px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(463) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -92.6s;
          animation-delay: -92.6s;
  transform: translate3d(4834px, 815px, 1739px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(464) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -92.8s;
          animation-delay: -92.8s;
  transform: translate3d(457px, 251px, 1299px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(465) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -93s;
          animation-delay: -93s;
  transform: translate3d(4059px, 111px, 7px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(466) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -93.2s;
          animation-delay: -93.2s;
  transform: translate3d(2408px, 287px, 381px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(467) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -93.4s;
          animation-delay: -93.4s;
  transform: translate3d(2715px, 819px, 305px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(468) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -93.6s;
          animation-delay: -93.6s;
  transform: translate3d(4636px, 758px, 1487px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(469) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -93.8s;
          animation-delay: -93.8s;
  transform: translate3d(1765px, 280px, 505px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(470) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -94s;
          animation-delay: -94s;
  transform: translate3d(3730px, 515px, 947px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(471) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -94.2s;
          animation-delay: -94.2s;
  transform: translate3d(3916px, 968px, 201px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(472) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -94.4s;
          animation-delay: -94.4s;
  transform: translate3d(4960px, 597px, 1730px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(473) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -94.6s;
          animation-delay: -94.6s;
  transform: translate3d(1574px, 964px, 1451px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(474) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -94.8s;
          animation-delay: -94.8s;
  transform: translate3d(523px, 391px, 696px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(475) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -95s;
          animation-delay: -95s;
  transform: translate3d(3204px, 277px, 1463px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(476) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -95.2s;
          animation-delay: -95.2s;
  transform: translate3d(4100px, 719px, 1389px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(477) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -95.4s;
          animation-delay: -95.4s;
  transform: translate3d(3573px, 455px, 822px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(478) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -95.6s;
          animation-delay: -95.6s;
  transform: translate3d(2312px, 287px, 1876px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(479) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -95.8s;
          animation-delay: -95.8s;
  transform: translate3d(1972px, 583px, 149px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(480) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -96s;
          animation-delay: -96s;
  transform: translate3d(1439px, 377px, 58px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(481) {
  height: 10px;
  width: 10px;
  -webkit-animation-delay: -96.2s;
          animation-delay: -96.2s;
  transform: translate3d(2143px, 356px, 47px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(482) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -96.4s;
          animation-delay: -96.4s;
  transform: translate3d(2799px, 74px, 1459px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(483) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -96.6s;
          animation-delay: -96.6s;
  transform: translate3d(204px, 758px, 102px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(484) {
  height: 6px;
  width: 6px;
  -webkit-animation-delay: -96.8s;
          animation-delay: -96.8s;
  transform: translate3d(349px, 285px, 405px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(485) {
  height: 5px;
  width: 5px;
  -webkit-animation-delay: -97s;
          animation-delay: -97s;
  transform: translate3d(4346px, 400px, 1747px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(486) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -97.2s;
          animation-delay: -97.2s;
  transform: translate3d(630px, 689px, 1613px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(487) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -97.4s;
          animation-delay: -97.4s;
  transform: translate3d(2483px, 235px, 418px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(488) {
  height: 11px;
  width: 11px;
  -webkit-animation-delay: -97.6s;
          animation-delay: -97.6s;
  transform: translate3d(1032px, 446px, 114px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(489) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -97.8s;
          animation-delay: -97.8s;
  transform: translate3d(1969px, 802px, 86px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(490) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -98s;
          animation-delay: -98s;
  transform: translate3d(4050px, 365px, 1163px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(491) {
  height: 9px;
  width: 9px;
  -webkit-animation-delay: -98.2s;
          animation-delay: -98.2s;
  transform: translate3d(887px, 623px, 476px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(492) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -98.4s;
          animation-delay: -98.4s;
  transform: translate3d(1099px, 613px, 1280px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(493) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -98.6s;
          animation-delay: -98.6s;
  transform: translate3d(276px, 776px, 317px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(494) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -98.8s;
          animation-delay: -98.8s;
  transform: translate3d(4741px, 186px, 825px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(495) {
  height: 2px;
  width: 2px;
  -webkit-animation-delay: -99s;
          animation-delay: -99s;
  transform: translate3d(23px, 269px, 749px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(496) {
  height: 3px;
  width: 3px;
  -webkit-animation-delay: -99.2s;
          animation-delay: -99.2s;
  transform: translate3d(162px, 341px, 731px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(497) {
  height: 1px;
  width: 1px;
  -webkit-animation-delay: -99.4s;
          animation-delay: -99.4s;
  transform: translate3d(4642px, 361px, 1810px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(498) {
  height: 4px;
  width: 4px;
  -webkit-animation-delay: -99.6s;
          animation-delay: -99.6s;
  transform: translate3d(1828px, 80px, 1740px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(499) {
  height: 12px;
  width: 12px;
  -webkit-animation-delay: -99.8s;
          animation-delay: -99.8s;
  transform: translate3d(1680px, 405px, 1196px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
}

.bubble:nth-child(500) {
  height: 8px;
  width: 8px;
  -webkit-animation-delay: -100s;
          animation-delay: -100s;
  transform: translate3d(3719px, 127px, 312px);
  background: #d6c638;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  filter: blur(1px);
} */

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  fill:rgb(0, 16, 247) !important;
  margin-left: -32px;
  margin-top: -32px;
  z-index: 99999;
}
.loading::before{
  content: "";
  position: fixed;
  left: 0;
  top:0;
  width: 100%;
  height: 100vh;
  background: rgba(245, 242, 242, 0.25);
  z-index: 1500;
  overflow: hidden;
}